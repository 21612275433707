export const Button = {
  baseStyle: {
    height: "40px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  variants: {
    secondary: {
      backgroundColor: "brand.secondary",
      color: "brand.white",
      borderRadius: "base",
      _disabled: {
        backgroundColor: "brand.secondary",
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "brand.secondary",
        _disabled: {
          backgroundColor: "brand.secondary",
          opacity: 0.6,
        },
      },
    },
    cancel: {
      backgroundColor: "brand.cancel",
      color: "brand.black",
    },
    primary: {
      backgroundColor: "brand.primary",
      color: "brand.white",
      _disabled: {
        backgroundColor: "brand.primary",
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "brand.primary",
        _disabled: {
          backgroundColor: "brand.primary",
          opacity: 0.6,
        },
      },
    },
    ghost: {
      backgroundColor: "transparent",
      color: "brand.secondary",
      _disabled: {
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "transparent",
      },
      _active: {
        backgroundColor: "transparent",
      },
    },
  },
};
