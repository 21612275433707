import { availabilityDropdownOptions } from "@containers/Wheels/constants";
import { Option } from "@shared/interfaces";

export const getOptionFromAvailability = (availability: boolean | undefined): Option<string> | null => {
  switch (availability) {
    case true:
      return availabilityDropdownOptions[0];
    case false:
      return availabilityDropdownOptions[1];
    default:
      return availabilityDropdownOptions[2];
  }
};
