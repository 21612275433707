import React from "react";
import { Thead, Tr, Th } from "@chakra-ui/react";

import { TableProperty } from "../interface";

export interface HeadProps<T> {
  properties: TableProperty<T>[];
}

function Head<T>(props: HeadProps<T>) {
  const { properties } = props;

  return (
    <Thead position="sticky" top="-1px" bg="brand.white">
      <Tr>
        {properties.map((prop) => (
          <Th key={prop.title}>{prop.title}</Th>
        ))}
      </Tr>
    </Thead>
  );
}

export default Head;
