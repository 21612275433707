import React, { useCallback } from "react";
import { Divider, HStack } from "@chakra-ui/react";
import { Wheel } from "@shared/models";
import { actions as sharedActions, actions as SharedActions } from "@shared/store";
import { MODAL_TYPES, OVERLAY_TYPES } from "@shared/constants";
import { deleteWheelPicture } from "@containers/Wheels/store/actions";
import { useDispatch } from "react-redux";
import { GalleryImage } from "@containers/Wheels/components/WheelViewContainer/WheelViewFullscreenImage/WheelViewFullscreenImage";
import { OverlaySize } from "@shared/interfaces";

import {
  WheelViewBlockTable,
  WheelViewGeneralBlock,
  WheelViewInventoryItemReportTable,
  WheelViewItemWrapper,
  WheelViewPicturesBlock,
  WheelViewVehicleBlock,
  WheelViewVerticalTable,
} from "..";

export interface WheelViewProps {
  wheel: Wheel;
  onAddPictures: () => void;
}

type WheelProperty = {
  [key: string]: number | undefined;
};

const WheelView: React.FC<WheelViewProps> = ({ wheel, onAddPictures }) => {
  const dispatch = useDispatch();

  const generateInventoryProperties = useCallback((propertyName: keyof Wheel, wheel: Wheel) => {
    const properties = [
      { title: "On Hands", key: "quantity_on_hand" },
      { title: "On PO", key: "quantity_on_po" },
      { title: "On SO", key: "quantity_on_sales" },
    ];

    const generatedProperties = properties.map(({ title, key }) => ({
      title,
      value: (wheel[propertyName] as unknown as WheelProperty)?.[key as keyof WheelProperty] ?? 0,
    }));

    const totalValue =
      ((wheel[propertyName] as unknown as WheelProperty)?.["quantity_on_hand" as keyof WheelProperty] || 0) +
      ((wheel[propertyName] as unknown as WheelProperty)?.["quantity_on_po" as keyof WheelProperty] || 0) -
      ((wheel[propertyName] as unknown as WheelProperty)?.["quantity_on_sales" as keyof WheelProperty] || 0);

    generatedProperties.push({ title: "Total", value: totalValue });

    return generatedProperties;
  }, []);

  const removePictureHandler = useCallback(
    (pictureId: number) => {
      if (pictureId) {
        dispatch(
          SharedActions.showModal({
            type: MODAL_TYPES.CONFIRM,
            props: {
              heading: "Remove image",
              content: "Are you sure you want to delete this picture?",
              cancelBtnText: "Cancel",
              successBtnText: "Delete",
              onSuccess: () => dispatch(deleteWheelPicture.request({ wheel_id: wheel.id, picture_id: pictureId })),
            },
          }),
        );
      }
    },
    [dispatch, wheel.id],
  );
  const openFullscreenGallery = useCallback(
    (images: GalleryImage[], index: number) => {
      dispatch(
        sharedActions.showOverlay({
          type: OVERLAY_TYPES.WHEEL_VIEW_FULLSCREEN_IMAGE,
          size: OverlaySize.FULLSCREEN,
          closeOnOverlayClick: false,
          props: { images, index, onClose: () => dispatch(sharedActions.hideOverlay()) },
        }),
      );
    },
    [dispatch],
  );

  return (
    <HStack spacing="16px" overflowY="auto" w="100%" p="0 32px 32px 32px">
      <WheelViewItemWrapper width="30%">
        <WheelViewGeneralBlock wheel={wheel} blockTitle="GENERAL" />
        <Divider />
        <WheelViewBlockTable
          blockTitle="WHEEL ASSEMBLY INVENTORY REPORT"
          properties={[
            { title: "On Hands", value: wheel.quantity_on_hand || 0 },
            { title: "On Purchase Order", value: wheel.quantity_on_po || 0 },
            { title: "On Sales Order", value: wheel.quantity_on_sales || 0 },
            {
              title: "Total Inventory",
              value: Math.max(
                (wheel.quantity_on_hand || 0) + (wheel.quantity_on_po || 0) - (wheel.quantity_on_sales || 0),
                0,
              ),
            },
          ]}
        />
        <Divider />
        <WheelViewVehicleBlock wheelVehicles={wheel.wheel_vehicles} blockTitle="VEHICLE DETAILS" />
      </WheelViewItemWrapper>
      <WheelViewItemWrapper width="40%">
        {wheel.rim && (
          <>
            <WheelViewVerticalTable
              blockTitle="RIM DETAILS"
              partNumber={wheel.rim.part_number}
              properties={[{ title: "Rim size", value: wheel.rim.rim_size }]}
            />
            <WheelViewInventoryItemReportTable properties={generateInventoryProperties("rim", wheel)} />
            <Divider />
          </>
        )}
        {wheel.disk && (
          <>
            <WheelViewVerticalTable
              blockTitle="DISK DETAILS"
              partNumber={wheel.disk.part_number}
              properties={[
                { title: "Hand Holes", value: wheel.disk.number_of_hand_holes },
                { title: "Bolts", value: wheel.disk.number_of_bolts },
                { title: "Bolt Circle", value: wheel.disk.bolt_circle },
                { title: "Bolt Diameter", value: wheel.disk.bolt_diameter },
                { title: "Stud Size", value: wheel.disk.stud_size },
                { title: "Inner Dimension", value: wheel.disk.inner_dimension },
              ]}
            />
            <WheelViewInventoryItemReportTable properties={generateInventoryProperties("disk", wheel)} />
            <Divider />
          </>
        )}
        {wheel.base && (
          <>
            <WheelViewVerticalTable
              blockTitle="BASE DETAILS"
              partNumber={wheel.base.part_number}
              properties={[
                { title: "Rim Size", value: wheel.base.rim_size || 0 },
                { title: "Number Of Pieces", value: wheel.base.number_of_pieces || 0 },
              ]}
            />
            <WheelViewInventoryItemReportTable properties={generateInventoryProperties("base", wheel)} />
            <Divider />
          </>
        )}
        {wheel.base?.lock_ring && (
          <>
            <WheelViewVerticalTable
              blockTitle="LOCK RING DETAILS"
              partNumber={wheel.base.lock_ring.part_number}
              properties={[]}
            />
            <WheelViewInventoryItemReportTable
              properties={generateInventoryProperties("base.lock_ring" as keyof Wheel, wheel)}
            />
            <Divider />
          </>
        )}
        {wheel.base?.side_flange && (
          <>
            <WheelViewVerticalTable
              blockTitle="SIDE FLANGE DETAILS"
              partNumber={wheel.base.side_flange.part_number}
              properties={[]}
            />
            <WheelViewInventoryItemReportTable
              properties={generateInventoryProperties("base.side_flange" as keyof Wheel, wheel)}
            />
          </>
        )}
      </WheelViewItemWrapper>
      <WheelViewItemWrapper width="30%">
        <WheelViewPicturesBlock
          onAddPictures={onAddPictures}
          wheelPictures={wheel.wheel_pictures}
          removePictureHandler={removePictureHandler}
          openFullscreenGallery={openFullscreenGallery}
        />
      </WheelViewItemWrapper>
    </HStack>
  );
};

export default WheelView;
