import { AnyType } from "@shared/interfaces";
import moment from "moment";

export const bytesToMegaBytes = (bytes: number): number => {
  return bytes / (1024 * 1024);
};

export const debounce = <F extends (...args: AnyType) => AnyType>(func: F, waitFor = 500) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  const debounced = (...args: AnyType) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export function getUniqueArray<T>(a: T[], property: keyof T) {
  return a.filter((item, pos, self) => self.findIndex((v) => v[property] === item[property]) === pos);
}

export const validateFile = (file: File | null, options: { maxSize?: number }): string => {
  if (file && options.maxSize && file.size > options.maxSize) {
    return `Max size of the file should be ${options.maxSize / 1000000} MB`;
  }

  return "";
};

export const getUSFormattedDate = (date: Date | string | undefined | null, timeZone?: string) => {
  return date ? new Date(date).toLocaleString("en-US", { timeZone }).split(",")[0] : "-";
};

export const getUSFormattedDateLong = (date: Date | string | undefined | null, timeZone?: string) => {
  return date
    ? new Date(date).toLocaleString("en-US", { year: "numeric", month: "long", day: "numeric", timeZone })
    : "-";
};

export const getUSFormatedTime = (date: Date | string, timeZone?: string) => {
  const tzDate = new Date(date).toLocaleString("en-US", { timeZone });

  return date ? moment(tzDate, "MM/DD/YYYY, hh:mm:ss A").locale("en-US").format("LT") : "-";
};

export const getUSFormatedDateTime = (date: Date | string, timeZone?: string) => {
  return date ? `${getUSFormattedDate(date, timeZone)} ${getUSFormatedTime(date, timeZone)}` : "-";
};

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result as string);
  });
}
