export enum SharedActionTypes {
  NAVIGATE = "@@SHARED/NAVIGATE",

  START_LOADING = "@@SHARED/START_LOADING",
  STOP_LOADING = "@@SHARED/STOP_LOADING",
  ADD_LOADING_TYPE = "@@SHARED/ADD_LOADING_TYPE",
  REMOVE_LOADING_TYPE = "@@SHARED/REMOVE_LOADING_TYPE",

  ADD_LOADING_SECTION = "@@SHARED/ADD_LOADING_SECTION",
  REMOVE_LOADING_SECTION = "@@SHARED/REMOVE_LOADING_SECTION",
  SHOW_NOTIFICATION = "@@SHARED/SHOW_NOTIFICATION",

  GET_USER_DETAIL = "@@SHARED/GET_USER_DETAIL",
  GET_USER_DETAIL_SUCCESS = "@@SHARED/GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILURE = "@@SHARED/GET_USER_DETAIL_FAILURE",

  GET_ROLES = "@@SHARED/GET_ROLES",
  GET_ROLES_SUCCESS = "@@SHARED/GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE = "@@SHARED/GET_ROLES_FAILURE",

  SHOW_OVERLAY = "@@SHARED/SHOW_OVERLAY",
  UPDATE_OVERLAY = "@@SHARED/UPDATE_OVERLAY",
  HIDE_OVERLAY = "@@SHARED/HIDE_OVERLAY",

  SHOW_MODAL = "@@SHARED/SHOW_MODAL",
  UPDATE_MODAL = "@@SHARED/UPDATE_MODAL",
  HIDE_MODAL = "@@SHARED/HIDE_MODAL",

  GET_JOBS = "@@SHARED/GET_JOBS",
  GET_JOBS_SUCCESS = "@@SHARED/GET_JOBS_SUCCESS",
  GET_JOBS_FAILURE = "@@SHARED/GET_JOBS_FAILURE",

  GET_INDUSTRIES = "@@SHARED/GET_INDUSTRIES",
  GET_INDUSTRIES_SUCCESS = "@@SHARED/GET_INDUSTRIES_SUCCESS",
  GET_INDUSTRIES_FAILURE = "@@SHARED/GET_INDUSTRIES_FAILURE",

  GET_ADDRESS = "@@SHARED/GET_ADDRESS",
  GET_ADDRESS_SUCCESS = "@@SHARED/GET_ADDRESS_SUCCESS",
  GET_ADDRESS_FAILURE = "@@SHARED/GET_ADDRESS_FAILURE",

  GET_LOCATION = "@@SHARED/GET_LOCATION",
  GET_LOCATION_SUCCESS = "@@SHARED/GET_LOCATION_SUCCESS",
  GET_LOCATION_FAILURE = "@@SHARED/GET_LOCATION_FAILURE",

  GET_TEAMS = "@@SHARED/GET_TEAMS",
  GET_TEAMS_SUCCESS = "@@SHARED/GET_TEAMS_SUCCESS",
  GET_TEAMS_FAILURE = "@@SHARED/GET_TEAMS_FAILURE",

  CREATE_TEAM = "@@SHARED/CREATE_TEAM",
  CREATE_TEAM_SUCCESS = "@@SHARED/CREATE_TEAM_SUCCESS",
  CREATE_TEAM_FAILURE = "@@SHARED/CREATE_TEAM_FAILURE",

  SET_LAST_CREATED_TEAM = "@@SHARED/SET_LAST_CREATED_TEAM",

  GET_JSA = "@@SHARED/GET_JSA",
  GET_JSA_SUCCESS = "@@SHARED/GET_JSA_SUCCESS",
  GET_JSA_FAILURE = "@@SHARED/GET_JSA_FAILURE",

  CREATE_PROJECT = "@@SHARED/CREATE_PROJECT",
  CREATE_PROJECT_SUCCESS = "@@SHARED/CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_FAILURE = "@@SHARED/CREATE_PROJECT_FAILURE",

  GET_CLIENTS = "@@SHARED/GET_CLIENTS",
  GET_CLIENTS_SUCCESS = "@@SHARED/GET_CLIENTS_SUCCESS",
  GET_CLIENTS_FAILURE = "@@SHARED/GET_CLIENTS_FAILURE",
}
