import { call, put, takeLatest } from "redux-saga/effects";
import { tokenHandler } from "@shared/utils";
import { navigate, startLoading, stopLoading } from "@shared/store/actions";
import { ActionWithPayload } from "@shared/interfaces";
import { NameOfRoutes } from "@shared/constants";

import { logout, login } from "./actions";
import api from "../api";
import { LoginShape } from "../interface";

function* loginUser(token: string) {
  yield tokenHandler.set(token);
  yield put(navigate("/"));
  yield put(login.success());
}

function* clearLocalStorage() {
  yield localStorage.clear();
}

function* logoutSaga() {
  yield clearLocalStorage();
  yield put(logout.success());
  yield put(navigate(NameOfRoutes.AUTH_LOGIN));
}

function* loginSaga({ payload }: ActionWithPayload<LoginShape>) {
  try {
    yield put(startLoading());
    yield clearLocalStorage();
    const { token }: { token: string } = yield call(api.login, payload);
    yield put(stopLoading());
    yield loginUser(token);
  } catch (error) {
    yield put(login.failure(error as Error));
    yield put(stopLoading());
  }
}

function* authSagas() {
  yield takeLatest(logout.request, logoutSaga);
  yield takeLatest(login.request, loginSaga);
}

export default authSagas;
