import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "@shared/hooks";
import { InputGroup, InputLeftElement, Input, InputRightElement } from "@chakra-ui/react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";

export interface InputSearchProps extends React.HTMLProps<HTMLInputElement> {
  onChangeSearch: (value: string) => void;
  showClearText?: boolean;
  showClose?: boolean;
  onChangeShowSearch?: (value: boolean) => void;
}

const InputSearch = (props: InputSearchProps) => {
  const { onChangeSearch, value, showClose, onChangeShowSearch } = props;
  const [inputValue, setInputValue] = useState<string>(value ? String(value) : "");
  const [showSearch, setShowSearch] = useState(!showClose);
  const debounceValue = useDebounce<string>(inputValue, 500);

  useEffect(() => {
    setInputValue(value ? String(value) : "");
    if (value) {
      setShowSearch(true);
    }
  }, [value]);

  useEffect(() => {
    onChangeShowSearch && onChangeShowSearch(showSearch);
  }, [onChangeShowSearch, showSearch]);

  useEffect(() => {
    if (debounceValue !== value && debounceValue !== undefined) {
      onChangeSearch(debounceValue);
    }
  }, [debounceValue, onChangeSearch, inputValue, value]);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const clearInput = useCallback(() => {
    setInputValue("");
  }, []);

  return (
    <InputGroup bg="brand.white" borderRadius="base" minWidth="40%">
      <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
      <Input
        value={inputValue}
        onChange={onChangeInput}
        placeholder="Search"
        focusBorderColor="brand.inputFocusBorder"
      />
      <InputRightElement>
        {inputValue && <CloseIcon color="brand.secondary" cursor="pointer" w="12px" onClick={clearInput} />}
      </InputRightElement>
    </InputGroup>
  );
};

export default InputSearch;
