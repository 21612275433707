import ImageGallery from "react-image-gallery";
import React, { FC, useState } from "react";
import { Box, Button, Spinner } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export interface GalleryImage {
  original: string;
  thumbnail: string;
  id: number;
}

export interface WheelViewFullscreenImageProps {
  images: GalleryImage[];
  index: number;
  onClose?: () => void;
}

export const WheelViewFullscreenImage: FC<WheelViewFullscreenImageProps> = (props) => {
  const { images, index, onClose } = props;
  const [loading, setLoading] = useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };
  return (
    <Box position="relative" minWidth="100%" minHeight="100%">
      <Button variant="ghost" onClick={onClose} position="absolute" top="16px" right="16px" zIndex="1">
        <CloseIcon />
      </Button>
      {loading && (
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex="1">
          <Spinner size="lg" color="brand.white" />
        </Box>
      )}
      <ImageGallery
        items={images}
        startIndex={index}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        onImageLoad={handleImageLoad}
      />
    </Box>
  );
};

export default WheelViewFullscreenImage;
