import React, { FC, useCallback, useMemo, useRef } from "react";
import { Button, Divider, Flex, ModalBody, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { Vehicle, Wheel } from "@shared/models";
import { BadgesList, GroupBadgesList, MultipleSelect } from "@shared/components";
import { Formik, FormikProps } from "formik";
import { wheelPictureTypeDropdownOptions } from "@containers/Wheels/constants";
import { Option } from "@shared/interfaces";
import { GroupBase } from "chakra-react-select";
import { AddWheelPicturesDto, WheelUploadPicturesShape } from "@containers/Wheels/interface";
import { ImagesUpload } from "@shared/components/Field/ImagesUpload";
import { ACCEPT_IMAGE_TYPES } from "@shared/constants";
import { addWheelPictures } from "@containers/Wheels/store/actions";
import { useLoader } from "@shared/hooks/LoaderHook";

import { initValues, prepareFormValues, validationSchema } from "./formHelpers";

interface WheelViewUploadPicturesModalProps {
  wheel: Wheel;
  onClose: () => void;
  onSubmit: (values: AddWheelPicturesDto) => void;
}

const MAX_FILE_SIZE = 10;

const WheelViewUploadPicturesModal: FC<WheelViewUploadPicturesModalProps> = ({ wheel, onClose, onSubmit }) => {
  const formikRef = useRef<FormikProps<WheelUploadPicturesShape>>(null);

  const { isLoading } = useLoader({
    name: "addWheelsPictures",
    actionTypes: useMemo(() => [addWheelPictures], []),
  });

  const vehicleOptions: GroupBase<Option<string, Vehicle>>[] = useMemo(() => {
    return wheel.wheel_vehicles.map((wv) => ({
      label: wv.vehicle.name,
      options: wv.vehicle_models.map((wm) => ({ label: wm.name, value: String(wm.id), object: wv.vehicle })),
    }));
  }, [wheel.wheel_vehicles]);

  const tireSizeOptions: Option<string>[] = useMemo(() => {
    return wheel.rim?.tire_sizes.map((wm) => ({ label: wm.name, value: String(wm.id) })) || [];
  }, [wheel.rim?.tire_sizes]);

  const handleRemoveTireSizesItem = useCallback((id: number) => {
    const tireSizesValues = formikRef.current?.values.tire_sizes || [];
    formikRef.current?.setFieldValue(
      "tire_sizes",
      tireSizesValues.filter((ts) => Number(ts.value) !== id),
    );
  }, []);

  const handleRemoveVehiclesItem = useCallback((id: number) => {
    const vehiclesValues = formikRef.current?.values.vehicles || [];
    formikRef.current?.setFieldValue(
      "vehicles",
      vehiclesValues.filter((ts) => Number(ts.value) !== id),
    );
  }, []);

  const handleUploadFiles = useCallback((files: string[]) => {
    formikRef.current?.setFieldValue("files", files);
  }, []);

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      innerRef={formikRef}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(prepareFormValues(values));

        setSubmitting(false);
      }}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={true}
    >
      {({ values, setFieldValue, isValid, handleSubmit }) => (
        <>
          <ModalHeader>Add Picture</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <Flex mb="15px">
                <MultipleSelect
                  isMulti={false}
                  isSearchable
                  name="picture_types"
                  label="Picture Type"
                  options={wheelPictureTypeDropdownOptions}
                  selected={values.picture_types}
                  setSelected={(option) => setFieldValue("picture_types", option)}
                  isRequired
                />
              </Flex>
              <Flex alignItems="center" gap="15px">
                <MultipleSelect
                  isMulti
                  isSearchable
                  label="Vehicle"
                  width="255px"
                  name="vehicles"
                  options={vehicleOptions}
                  selected={values.vehicles}
                  setSelected={(option) => setFieldValue("vehicles", option)}
                  isRequired
                />
                <MultipleSelect
                  isMulti
                  isSearchable
                  width="255px"
                  label="Tire Size"
                  options={tireSizeOptions}
                  selected={values.tire_sizes}
                  setSelected={(option) => setFieldValue("tire_sizes", option)}
                  isRequired
                />
              </Flex>
              <Flex mt="15px">
                <BadgesList title="Tire Size" options={values.tire_sizes} onRemove={handleRemoveTireSizesItem} />
              </Flex>
              <GroupBadgesList<Vehicle> options={values.vehicles} onRemove={handleRemoveVehiclesItem} />
              <Divider />
              <ImagesUpload types={ACCEPT_IMAGE_TYPES} maxFileSize={MAX_FILE_SIZE} onUploadFile={handleUploadFiles} />
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant="cancel" mr={3} onClick={() => onClose()} isDisabled={isLoading}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              isDisabled={isLoading || !(isValid && values.files.length)}
              isLoading={isLoading}
              onClick={() => handleSubmit()}
            >
              Add
            </Button>
          </ModalFooter>
        </>
      )}
    </Formik>
  );
};

export default WheelViewUploadPicturesModal;
