import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logout as logoutAction } from "@containers/Auth/store/actions";
import { actions } from "@shared/store";
import { MODAL_TYPES } from "@shared/constants";
import { actions as WheelsAction } from "@containers/Wheels/store";

function useLogout() {
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    dispatch(
      actions.showModal({
        type: MODAL_TYPES.CONFIRM,
        props: {
          heading: "Logout",
          content: "Are you sure you want to logout?",
          cancelBtnText: "Cancel",
          successBtnText: "Logout",
          onSuccess: () => {
            dispatch(WheelsAction.setFilter(null));
            dispatch(WheelsAction.clearWheelsList());
            dispatch(logoutAction.request());
          },
        },
      }),
    );
  }, [dispatch]);

  return logout;
}

export default useLogout;
