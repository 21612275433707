import { Box, ButtonGroup, Flex, Heading } from "@chakra-ui/react";
import { Button } from "@shared/components";
import React, { FC } from "react";
import { ReactComponent as BackButton } from "@assets/files/icons/general/back.svg";
import { ReactComponent as Edit } from "@assets/files/icons/general/edit.svg";
import { ReactComponent as Sync } from "@assets/files/icons/general/sync.svg";

export interface WheelViewContainerTitleBlockProps {
  partNumber?: string;
  editHandler: () => void;
  closeBtnHandler: () => void;
  loading?: boolean;
}

const WheelViewContainerTitleBlock: FC<WheelViewContainerTitleBlockProps> = (props) => {
  const { partNumber, editHandler, closeBtnHandler, loading } = props;
  return (
    <Flex alignItems="center" justifyContent="space-between" p="16px">
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Button variant="ghost" p={0} leftIcon={<BackButton />} onClick={closeBtnHandler} />
        <Heading size="md">{loading ? "Loading..." : partNumber}</Heading>
      </Box>
      <ButtonGroup gap="2">
        <Button variant="ghost" leftIcon={<Sync />} isDisabled={loading}>
          Sync
        </Button>
        <Button variant="secondary" leftIcon={<Edit />} onClick={editHandler} isDisabled={loading}>
          Edit
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default WheelViewContainerTitleBlock;
