import { HStack, SkeletonText } from "@chakra-ui/react";

const WheelViewSkeleton = () => {
  return (
    <HStack w="100%" p="0 32px 32px 32px" height="100%" overflow="hidden">
      <SkeletonText mt="4" noOfLines={30} spacing="4" skeletonHeight="10px" width="30%" />
      <SkeletonText mt="4" noOfLines={30} spacing="4" skeletonHeight="10px" width="40%" />
      <SkeletonText mt="4" noOfLines={30} spacing="4" skeletonHeight="10px" width="30%" />
    </HStack>
  );
};

export default WheelViewSkeleton;
