import { Box, SkeletonText, VStack } from "@chakra-ui/react";
import React, { FC } from "react";
import WheelEditFormHeader from "@containers/Wheels/components/WheelEditContainer/WheelEdit/WheelEditFormHeader";

export interface WheelEditSkeletonProps {
  onBack: () => void;
}

const WheelEditSkeleton: FC<WheelEditSkeletonProps> = (props) => {
  const { onBack } = props;
  return (
    <VStack maxW="wheelPageMaxWidth" w="full" justifyContent="flex-start" p="0 32px">
      <Box w="100%">
        <WheelEditFormHeader onBackBtnClick={onBack} />
      </Box>
      <SkeletonText mt="24" noOfLines={5} spacing="4" skeletonHeight="15px" height="30%" width="100%" />
      <SkeletonText mt="4" noOfLines={5} spacing="4" skeletonHeight="15px" height="40%" width="100%" />
      <SkeletonText mt="4" noOfLines={5} spacing="4" skeletonHeight="15px" height="30%" width="100%" />
      <SkeletonText mt="4" noOfLines={5} spacing="4" skeletonHeight="15px" height="30%" width="100%" />
    </VStack>
  );
};

export default WheelEditSkeleton;
