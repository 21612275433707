import { AnyType } from "./Any.interface";

export interface ModalProps {
  type: string;
  props: AnyType;
  leaveConfirm?: boolean;
  closeOnOverlayClick?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

export enum OverlaySize {
  NORMAL = "normal",
  FULLSCREEN = "fullscreen",
}

export interface OverlayProps {
  size?: OverlaySize;
  type: string;
  closeOnOverlayClick: boolean;
  leaveConfirm?: boolean;
  props: AnyType;
}
