import React, { useCallback, useMemo } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Option } from "@shared/interfaces";
import { Vehicle, VehicleModel } from "@shared/models";
import { BadgesList, Button, MultipleSelect } from "@shared/components";
import { UpdateWheelDataWheelVehicle } from "@containers/Wheels/interface";
import { ReactComponent as Delete } from "@assets/files/icons/general/delete.svg";
import { actions } from "@shared/store";
import { useDispatch } from "react-redux";
import { MODAL_TYPES } from "@shared/constants";

export interface VehicleDetailsBlockProps {
  usedVehicleIds: number[];
  wheelVehicle: UpdateWheelDataWheelVehicle;
  vehicles: Vehicle[];
  vehicleModels: VehicleModel[];
  setVehicle: (option: Option<string>) => void;
  setVehicleModels: (options: Option<string>[]) => void;
  removeVehicleModel: (id: number) => void;
  removeVehicle?: () => void;
  onManuallyAddVehicleHandler?: (name: string) => void;
  onManuallyAddVehicleModelHandler?: (name: string, vehicleId: number | undefined) => void;
}

const VehicleDetailsBlock: React.FC<VehicleDetailsBlockProps> = ({
  usedVehicleIds,
  wheelVehicle,
  vehicles,
  vehicleModels,
  setVehicle,
  removeVehicle,
  setVehicleModels,
  removeVehicleModel,
  onManuallyAddVehicleHandler,
  onManuallyAddVehicleModelHandler,
}) => {
  const dispatch = useDispatch();
  const vehicleOptions = useMemo<Option<string>[]>(
    () =>
      vehicles
        .filter(({ id }) => id && (id === wheelVehicle.vehicle?.id || !usedVehicleIds.includes(id)))
        .map(({ id, name }) => ({ value: String(id), label: name || "" })),
    [usedVehicleIds, vehicles, wheelVehicle.vehicle?.id],
  );
  const vehicleModelOptions = useMemo<Option<string>[]>(
    () => vehicleModels.map(({ id, name }) => ({ value: String(id), label: name })),
    [vehicleModels],
  );

  const setVehicleHandler = useCallback(
    (options: Option<string>[]) => {
      if (options[0]) {
        setVehicle(options[0]);
      }
    },
    [setVehicle],
  );
  const setVehicleModelsHandler = useCallback(
    (options: Option<string>[]) => {
      setVehicleModels(options);
    },
    [setVehicleModels],
  );
  const removeVehicleModelsHandler = useCallback(
    (id: number) => {
      removeVehicleModel(id);
    },
    [removeVehicleModel],
  );
  const removeVehicleHandler = useCallback(() => {
    if (removeVehicle) {
      dispatch(
        actions.showModal({
          type: MODAL_TYPES.CONFIRM,
          props: {
            heading: "Remove Vehicle",
            content: `Please confirm that you would like to remove vehicle.`,
            cancelBtnText: "Cancel",
            successBtnText: "Remove",
            onSuccess: () => removeVehicle(),
          },
        }),
      );
    }
  }, [dispatch, removeVehicle]);

  const selectedVehicle = useMemo(() => {
    if (wheelVehicle.vehicle) {
      return vehicleOptions.filter(({ value }) => value === String(wheelVehicle.vehicle?.id));
    }
    return [];
  }, [vehicleOptions, wheelVehicle.vehicle]);

  const selectedVehicleModels = useMemo(() => {
    const selectedModelIds = wheelVehicle.vehicle?.models.map(({ id }) => id);
    return vehicleModelOptions.filter(({ value }) => selectedModelIds?.includes(Number(value)));
  }, [wheelVehicle.vehicle?.models, vehicleModelOptions]);

  const handleAddVehicleModel = useCallback(
    (name: string) => {
      if (onManuallyAddVehicleModelHandler) {
        const vehicleId = selectedVehicle[0] ? Number(selectedVehicle[0].value) : undefined;

        onManuallyAddVehicleModelHandler(name, vehicleId);
      }
    },
    [onManuallyAddVehicleModelHandler, selectedVehicle],
  );

  return (
    <Box bg="#F9F9F9" borderRadius="8px" p="16px" w="full">
      <Flex flexWrap="wrap" gap="16px" alignItems="center">
        <MultipleSelect
          isMulti={false}
          isSearchable
          width="300px"
          label="Vehicle Make"
          options={vehicleOptions}
          selected={selectedVehicle}
          setSelected={setVehicleHandler}
          isRequired
          onAddItem={onManuallyAddVehicleHandler}
          addItemModalType="Vehicle"
        />
        <MultipleSelect
          isMulti
          width="300px"
          label="Vehicle Model"
          options={vehicleModelOptions}
          selected={selectedVehicleModels}
          setSelected={setVehicleModelsHandler}
          isRequired
          onAddItem={handleAddVehicleModel}
          addItemModalType="Model"
        />
        {removeVehicle && (
          <Button variant="ghost" leftIcon={<Delete />} ml="auto" mt="16px" onClick={removeVehicleHandler}>
            Remove Vehicle
          </Button>
        )}
      </Flex>
      <Flex gap="12px" alignItems="center" mt="16px" h="32px">
        <BadgesList title="Models" options={selectedVehicleModels} onRemove={removeVehicleModelsHandler} />
      </Flex>
    </Box>
  );
};

export default VehicleDetailsBlock;
