import React, { useCallback } from "react";
import { useDebouncedFunction } from "@shared/hooks";
import { Table, TableContainer, Tbody } from "@chakra-ui/react";

import { TableProperty } from "./interface";
import { Head } from "./Head";
import { Row } from "./Row";

export interface TableProps<T> {
  items: T[];
  properties: TableProperty<T>[];
  onFinishScroll?: () => void;
  onRowClick?: (item: T) => void;
  selectedItems?: T[];
}

function ChakraTable<T>(props: TableProps<T>) {
  const { items, properties, onFinishScroll, onRowClick } = props;

  const debouncedFinishScroll = useDebouncedFunction(onFinishScroll);

  const onScroll = useCallback(
    (event: React.SyntheticEvent<Element>) => {
      const maxScroll = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
      if (event.currentTarget.scrollTop >= maxScroll - 1 && debouncedFinishScroll) {
        debouncedFinishScroll();
      }
    },
    [debouncedFinishScroll],
  );

  return (
    <TableContainer
      onScroll={onScroll}
      h="calc(100vh - 120px)"
      overflowY="scroll"
      borderRadius="table"
      boxShadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)"
    >
      <Table variant={onRowClick ? "stripedWithTrAction" : "striped"}>
        <Head<T> properties={properties} />
        <Tbody>
          {items.map((item, index) => (
            <Row<T> key={index} item={item} properties={properties} onClick={() => onRowClick?.(item)} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default ChakraTable;
