import React from "react";
import { Badge, HStack, Flex, Text, VStack, Heading } from "@chakra-ui/react";
import { WheelVehicle } from "@shared/models";

export interface WheelViewVehicleBlockProps {
  wheelVehicles: WheelVehicle[];
  blockTitle?: string;
}

const WheelViewVehicleBlock: React.FC<WheelViewVehicleBlockProps> = ({ wheelVehicles, blockTitle }) => {
  return (
    <VStack spacing="8px" alignItems="flex-start">
      <Heading as="h4" fontSize="12px" color="brand.secondarySubtitle" letterSpacing="1.2px" fontWeight="600">
        {blockTitle}
      </Heading>
      {wheelVehicles.map(({ id, vehicle, vehicle_models }) => (
        <HStack key={id} spacing="8px" alignItems="flex-start">
          <Text>{vehicle.name}</Text>
          <Flex key={id} gap="8px" flexWrap="wrap">
            {vehicle_models.map((model) => (
              <Badge key={model.id} variant="readOnlyProp">
                {model.name}
              </Badge>
            ))}
          </Flex>
        </HStack>
      ))}
    </VStack>
  );
};

export default WheelViewVehicleBlock;
