import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { ReactComponent as Remove } from "@assets/files/icons/general/clear.svg";

export interface BadgeItemProps {
  id: number;
  name: string;
  onRemove: (id: number) => void;
}

const BadgeItem: React.FC<BadgeItemProps> = ({ id, name, onRemove }) => {
  return (
    <HStack spacing="8px" borderRadius="6px" backgroundColor="#BEE3F8" p="0 12px" h="32px">
      <Text color="brand.black">{name}</Text>
      <Remove cursor="pointer" onClick={() => onRemove(id)} />
    </HStack>
  );
};

export default BadgeItem;
