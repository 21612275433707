import React from "react";
import { Box, Heading } from "@chakra-ui/react";

export interface WheelViewInventoryItemReportTableProps {
  properties: { title: string; value: string | number }[];
  blockTitle?: string;
}

const WheelViewInventoryItemReportTable: React.FC<WheelViewInventoryItemReportTableProps> = ({
  properties,
  blockTitle,
}) => {
  return (
    <>
      <Heading as="h4" fontSize="12px" color="brand.secondarySubtitle" letterSpacing="1.2px" fontWeight="600" mb="8px">
        {blockTitle}
      </Heading>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        bg="brand.bg"
        borderRadius="base"
        p="16px"
        minHeight="80px"
      >
        {properties.map(({ title, value }, i) => (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            key={i}
            height="100%"
            borderLeft={i ? "1px solid #D3D3D3" : ""}
            paddingLeft="16px"
            flexBasis={`${100 / properties.length}%`}
          >
            <Box fontSize="14px" color="brand.secondarySubtitle" fontWeight="700">
              {title}
            </Box>
            <Box fontWeight="700">{value}</Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default WheelViewInventoryItemReportTable;
