import React, { FC, useCallback, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { showNotification } from "@shared/store/actions";
import { ReactComponent as UploadIcon } from "@assets/files/icons/general/upload.svg";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch } from "react-redux";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { fileToBase64 } from "@shared/utils";

interface ImagesUploadProps {
  types?: string[];
  onUploadFile: (blobFiles: string[]) => void;
  maxFileSize?: number; //MB
  maxFiles?: number;
}

const DEFAULT_FILES = 5;

const ImagesUpload: FC<ImagesUploadProps> = ({ types, maxFiles = DEFAULT_FILES, maxFileSize, onUploadFile }) => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState<string[]>([]);

  const handleError = useCallback(
    (errorMessage: string) => {
      dispatch(
        showNotification({
          message: errorMessage,
          appearance: "error",
        }),
      );
    },
    [dispatch],
  );

  const handleRemoveFile = useCallback(
    (index: number) => {
      const copyFiles = [...files];
      copyFiles.splice(index, 1);

      setFiles(copyFiles);
      onUploadFile(copyFiles);
    },
    [files, onUploadFile],
  );

  const handleChange = useCallback(
    async (newFiles: FileList) => {
      if (!newFiles.length) return;

      const convertedFiles: string[] = await Promise.all(Array.from(newFiles).map((f: File) => fileToBase64(f)));
      const slicedFiles = [...convertedFiles, ...files].slice(0, maxFiles);

      setFiles(slicedFiles);
      onUploadFile(slicedFiles);
    },
    [files, maxFiles, onUploadFile],
  );

  return (
    <Flex w="100%" flexDir="column">
      <Text mb="10px" fontSize="12px" fontWeight="700" color="brand.label" letterSpacing="1px">
        IMAGES
      </Text>
      {!files.length ? (
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={types}
          fileOrFiles={files}
          onTypeError={handleError}
          onSizeError={handleError}
          multiple={true}
          maxSize={maxFileSize}
        >
          <Flex
            cursor="pointer"
            flexDir="column"
            borderRadius="8px"
            borderColor="gray.50"
            borderWidth="1px"
            background="#F9F9F9"
            alignItems="center"
            justifyContent="center"
            w="100%"
            h="200px"
          >
            <Box mb="15px">
              <UploadIcon />
            </Box>
            <Flex flexDir="column" alignItems="center">
              <Text textStyle="secondarySubtitle">Drag & Drop file here</Text>
              <Text textStyle="secondarySubtitle" mt="5px" mb="5px">
                or
              </Text>
              <Text textStyle="secondarySubtitle" fontWeight="700" color="brand.primary">
                browse files
              </Text>
            </Flex>
            <Box></Box>
          </Flex>
        </FileUploader>
      ) : null}
      <Flex gap="15px" mt="15px" mb="15px" flexWrap="wrap">
        {files.length && files.length !== maxFiles ? (
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={types}
            fileOrFiles={files}
            onTypeError={handleError}
            onSizeError={handleError}
            multiple={true}
            maxSize={maxFileSize}
          >
            <Flex
              cursor="pointer"
              width="88px"
              height="88px"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              background="gray.40"
              borderStyle="dashed"
              borderWidth="2px"
              borderColor="#7E7E7E"
            >
              <AddIcon color="#7E7E7E" />
            </Flex>
          </FileUploader>
        ) : null}
        {files?.map((f, index) => (
          <Flex
            key={index}
            backgroundPosition="center"
            backgroundSize="contain"
            backgroundImage={f}
            width="88px"
            height="88px"
            borderRadius="8px"
            justifyContent="right"
          >
            <Flex
              w="24px"
              h="24px"
              borderRadius="8px"
              color="white"
              backgroundColor="rgba(23, 23, 23, 0.7)"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => handleRemoveFile(index)}
            >
              <CloseIcon w="10px" h="10px" />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ImagesUpload;
