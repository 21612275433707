import React from "react";
import { Box, Heading } from "@chakra-ui/react";

export interface WheelViewBlockTableProps {
  properties: { title: string; value: string | number; isBold?: boolean }[];
  width?: string;
  blockTitle?: string;
}

const WheelViewBlockTable: React.FC<WheelViewBlockTableProps> = ({ properties, blockTitle }) => {
  return (
    <>
      <Heading as="h4" fontSize="12px" color="brand.secondarySubtitle" letterSpacing="1.2px" fontWeight="600" mb="8px">
        {blockTitle}
      </Heading>
      <Box display="flex" flexDirection="column" bg="brand.bg" borderRadius="base" p="20px 16px 0 16px">
        {properties.map(({ title, value }, i) => (
          <Box display="flex" justifyContent="space-between" alignItems="center" key={i} mb="24px">
            <Box fontSize="14px" color="brand.secondarySubtitle" fontWeight="400">
              {title}
            </Box>
            <Box fontWeight="700">{value}</Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default WheelViewBlockTable;
