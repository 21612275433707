export const ACCEPT_IMAGE_TYPES = ["jpg", "jpeg", "png"];

export enum MODAL_TYPES {
  CREATE_MANUALLY_ITEM = "CREATE_MANUALLY_ITEM",
  UPLOAD_WHEEL_PICTURES = "UPLOAD_WHEEL_PICTURES",
  CONFIRM = "CONFIRM",
}

export enum OVERLAY_TYPES {
  WHEELS_LIST_ADVANCED_FILTER = "WHEELS_LIST_ADVANCED_FILTER",
  WHEEL_VIEW_FULLSCREEN_IMAGE = "WHEEL_VIEW_FULLSCREEN_IMAGE",
}
