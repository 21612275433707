import { TableProperty } from "@shared/components/Table";
import { Wheel } from "@shared/models";

export const wheelsTableProperties: TableProperty<Wheel>[] = [
  {
    title: "Part number",
    key: "part_number",
    get: (item) => <span>{item.part_number}</span> || "-",
  },
  {
    title: "On hands",
    key: "quantity_on_hand",
    get: (item) => <span>{item.quantity_on_hand}</span> || "-",
  },
  {
    title: "On sales order",
    key: "quantity_on_sales",
    get: (item) => <span>{item.quantity_on_sales}</span> || "-",
  },
  {
    title: "Total inventory",
    key: "id",
    get: (item) =>
      <span>{(item.quantity_on_hand ?? 0) + (item.quantity_on_po ?? 0) - (item.quantity_on_sales ?? 0)}</span> || "-",
  },
];
