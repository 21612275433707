import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";

const selectInvestorFile = (state: AppState) => state.wheels;

export const getWheelsList = () => createSelector(selectInvestorFile, (state) => state.wheels);
export const getWheelsAdvancedFilterData = () =>
  createSelector(selectInvestorFile, (state) => state.wheelsAdvancedFilterData);
export const getFilter = () => createSelector(selectInvestorFile, (state) => state.filter);
export const getWheelsTotal = () => createSelector(selectInvestorFile, (state) => state.wheelsTotal);
export const selectWheel = () => createSelector(selectInvestorFile, (state) => state.wheel);
export const selectTireSizes = () => createSelector(selectInvestorFile, (state) => state.tireSizes);
export const selectVehicles = () => createSelector(selectInvestorFile, (state) => state.vehicles);
