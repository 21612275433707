import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
  FormHelperText,
  InputLeftElement,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useState, FC } from "react";
import { Field } from "formik";

import "./index.scss";

interface InputProps extends ChakraInputProps {
  label?: string;
  isShowPasswordIcon?: boolean;
  inputLeftElement?: ChakraInputProps["children"];
  inputRightElement?: ChakraInputProps["children"];
  showValidCheckmark?: boolean;
  isRequiredField?: boolean;
  helpText?: string;
}

const Input: FC<InputProps> = ({
  label,
  isShowPasswordIcon,
  inputLeftElement,
  inputRightElement,
  showValidCheckmark,
  isRequiredField,
  helpText,
  ...inputProps
}) => {
  const [isShowPassword, setShowPassword] = useState(false);
  return (
    <FormControl isRequired={isRequiredField} mt={4} className="input">
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <Field
          as={ChakraInput}
          {...inputProps}
          _focusVisible={{ boxShadow: "transparent" }}
          type={isShowPassword ? "text" : inputProps.type}
          variant="outline"
          bg="brand.white"
          borderRadius="base"
        />
        {isShowPasswordIcon && (
          <InputRightElement display="flex" h="100%" w="45px" alignItems="center" justifyContent="center">
            <IconButton
              bg="brand.white"
              aria-label={isShowPassword ? "Hide password" : "Show password"}
              icon={isShowPassword ? <ViewOffIcon /> : <ViewIcon />}
              onClick={() => setShowPassword(!isShowPassword)}
            />
          </InputRightElement>
        )}
        {inputLeftElement && (
          <InputLeftElement display="flex" h="100%" w="35px" alignItems="center" justifyContent="center">
            {inputLeftElement}
          </InputLeftElement>
        )}

        {inputRightElement && (showValidCheckmark === undefined || showValidCheckmark) && (
          <InputRightElement display="flex" h="100%" w="35px" alignItems="center" justifyContent="center">
            {inputRightElement}
          </InputRightElement>
        )}
      </InputGroup>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};

export default Input;
