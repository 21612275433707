import React, { FC } from "react";
import { Select } from "chakra-react-select";
import { SelectProps } from "@shared/interfaces";

const SelectInput: FC<SelectProps> = (props) => {
  return (
    <Select
      {...props}
      isSearchable={true}
      chakraStyles={{
        container: (provided) => ({
          ...provided,
          cursor: "pointer",
        }),
        control: (provided) => ({
          ...provided,
          height: "40px",
          borderRadius: "base",
          borderColor: "gray.100 !important",
          boxShadow: "none !important",
          fontSize: "16px",
          background: "white",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          background: "none",
        }),
        downChevron: (provided) => ({
          ...provided,
          color: "gray.650",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#7E7E7E" : state.isFocused ? "base.white" : "transparent",
          fontSize: "16px",
        }),
      }}
    />
  );
};

export default SelectInput;
