import React from "react";
import { Box, Divider, VStack, Checkbox, Text, SystemStyleObject } from "@chakra-ui/react";
import { components, OptionProps, MenuListProps, ControlProps, DropdownIndicatorProps } from "chakra-react-select";
import { Button } from "@shared/components";
import { ReactComponent as Plus } from "@assets/files/icons/general/plus.svg";
import { Option as SelectOption } from "@shared/interfaces";
import { actions } from "@shared/store";
import { AnyAction, Dispatch } from "redux";
import { MODAL_TYPES } from "@shared/constants";

export const Option = (props: OptionProps<{ value: string; label: string }>) => {
  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        style: {
          ...props.innerProps.style,
          cursor: "pointer",
          backgroundColor: props.isFocused ? "#F1F1F1" : "transparent",
          padding: "12px",
        },
      }}
    >
      <Checkbox isChecked={props.isSelected} isDisabled variant="selectOption">
        {props.data.label}
      </Checkbox>
    </components.Option>
  );
};

export const MenuList =
  (onAddItem: ((name: string) => void) | undefined, itemName: string | undefined, dispatch: Dispatch<AnyAction>) =>
  (props: MenuListProps<{ value: string; label: string }>) => {
    const addManuallyBtnHandler = () => {
      dispatch(
        actions.showModal({
          type: MODAL_TYPES.CREATE_MANUALLY_ITEM,
          props: {
            onSuccess: onAddItem,
            itemName,
          },
        }),
      );
    };

    return (
      <VStack bg="brand.white" w="full">
        <VStack maxHeight="200px" overflowY="scroll" w="full">
          {props.children}
        </VStack>
        {onAddItem ? (
          <Box w="full" px="16px" mb="16px !important">
            <Divider mt={0} />
            <Text fontSize="14px" color="brand.secondarySubtitle">
              Can't find what you are looking for?
            </Text>
            <Button variant="ghost" color="brand.primary" leftIcon={<Plus />} p={0} onClick={addManuallyBtnHandler}>
              Add Manually
            </Button>
          </Box>
        ) : null}
      </VStack>
    );
  };

export const chakraStyles = {
  container: (provided: SystemStyleObject) => ({
    ...provided,
    cursor: "pointer",
  }),
  control: (provided: SystemStyleObject, state: ControlProps<SelectOption<string>>) => ({
    ...provided,
    height: "48px",
    borderRadius: "base",
    borderColor: state.menuIsOpen ? "brand.black !important" : "gray.50 !important",
    boxShadow: "none !important",
    fontSize: "16px",
    background: "white",
  }),
  placeholder: (provided: SystemStyleObject) => ({
    ...provided,
    color: "brand.secondarySubtitle",
  }),
  dropdownIndicator: (provided: SystemStyleObject, state: DropdownIndicatorProps<SelectOption<string>>) => ({
    ...provided,
    background: "none",
    color: state.selectProps.menuIsOpen ? "brand.secondary" : "gray.700",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : undefined,
  }),
  indicatorSeparator: (provided: SystemStyleObject) => ({
    ...provided,
    display: "none",
  }),
  group: (provided: SystemStyleObject) => ({
    ...provided,
    width: "100%",
  }),
  menu: (provided: SystemStyleObject) => ({
    ...provided,
    boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)",
    overflow: "hidden",
    borderRadius: "base",
    border: "1px solid",
    borderColor: "gray.50",
  }),
};

export const getCustomComponents = (
  onAddItem: ((name: string) => void) | undefined,
  addItemModalType: string | undefined,
  dispatch: Dispatch<AnyAction>,
) => {
  return { MenuList: MenuList(onAddItem, addItemModalType, dispatch) };
};

export const getCustomCheckboxesComponents = (
  onAddItem: ((name: string) => void) | undefined,
  addItemModalType: string | undefined,
  dispatch: Dispatch<AnyAction>,
) => {
  return { ...getCustomComponents(onAddItem, addItemModalType, dispatch), Option };
};
