import React, { useCallback, useMemo, useRef } from "react";
import { Box, Divider, Flex, Heading, VStack } from "@chakra-ui/react";
import { Option } from "@shared/interfaces";
import { TireSize, Vehicle, VehicleModel, Wheel } from "@shared/models";
import { AsyncMultipleSelect, BadgesList, Button } from "@shared/components";
import { UpdateWheel, UpdateWheelData } from "@containers/Wheels/interface";
import { ReactComponent as Delete } from "@assets/files/icons/general/delete.svg";
import { FieldArray, Formik, FormikProps } from "formik";
import WheelEditFormHeader from "@containers/Wheels/components/WheelEditContainer/WheelEdit/WheelEditFormHeader";
import { actions as WheelsActions, selectors as WheelsSelectors } from "@containers/Wheels/store";
import { useDispatch } from "react-redux";

import {
  createValidationSchema,
  getInitValues,
  getTireSizesOptions,
  prepareSubmitValues,
  prepareTireSizeToOption,
  SetFieldValueType,
} from "./formHelpers";
import { VehicleDetailsBlock } from "..";

export interface WheelViewProps {
  tireSizes: TireSize[];
  wheel: Wheel;
  vehicles: Vehicle[];
  onRemove: () => void;
  onSubmit: (preparedValues: UpdateWheel) => void;
  onCancelAndBackBtnClick: () => void;
  isFormChanged: boolean;
  setIsFormChanged: (isFormChanged: boolean) => void;
}

const WheelEdit: React.FC<WheelViewProps> = (props) => {
  const dispatch = useDispatch();

  const { wheel, tireSizes, vehicles, onRemove, onSubmit, onCancelAndBackBtnClick, setIsFormChanged } = props;
  const formValues = useMemo(() => getInitValues(wheel), [wheel]);

  const formikRef = useRef<FormikProps<UpdateWheelData>>(null);

  const tireSizeOptions = useMemo<Option<string>[]>(
    () => tireSizes.map(({ id, name }) => ({ value: String(id), label: name })),
    [tireSizes],
  );

  const getSelectedTireSizes = useCallback(
    (values: UpdateWheelData) => {
      const selectedTireSizeIds = values.rim?.tire_sizes.map(({ id }) => id);
      return tireSizeOptions.filter(({ value }) => selectedTireSizeIds?.includes(Number(value)));
    },
    [tireSizeOptions],
  );

  const getUsedVehicleIds = useCallback((values: UpdateWheelData) => {
    const ids: number[] = [];
    values.wheel_vehicles.forEach(({ vehicle }) => {
      if (vehicle?.id) {
        ids.push(vehicle.id);
      }
    });
    return ids;
  }, []);

  const updateTierSizes = useCallback((options: Option<string>[], values: UpdateWheelData) => {
    const existingTireSizeIds = values.rim.tire_sizes.map(({ id }) => id);
    const newTireSizeOptions = options
      .filter(({ value }) => !existingTireSizeIds.includes(Number(value)))
      .map(({ value, label }) => ({
        id: Number(value),
        name: label,
      }));

    formikRef.current?.setFieldValue("rim.tire_sizes", [...values.rim.tire_sizes, ...newTireSizeOptions]);
  }, []);

  const removeTierSize = useCallback((id: number) => {
    formikRef.current?.setFieldValue(
      "rim.tire_sizes",
      formikRef.current.values.rim.tire_sizes.filter((tireSize: TireSize) => tireSize.id !== id),
    );
  }, []);

  const setVehicle = useCallback(
    (index: number, option: Option<string>, setFieldValue: SetFieldValueType, values: UpdateWheelData) => {
      const newWheelVehicles = [...values.wheel_vehicles];
      newWheelVehicles[index] = {
        ...newWheelVehicles[index],
        vehicle: {
          id: Number(option.value),
          name: option.label,
          models: [...(newWheelVehicles[index]?.vehicle?.models || [])],
        },
      };
      setFieldValue("wheel_vehicles", newWheelVehicles);
    },
    [],
  );

  const setVehicleModels = useCallback(
    (index: number, options: Option<string>[], values: UpdateWheelData, setFieldValue: SetFieldValueType) => {
      const newWheelVehicles = [...values.wheel_vehicles];
      newWheelVehicles[index] = {
        ...newWheelVehicles[index],
        vehicle: {
          ...newWheelVehicles[index].vehicle,
          models: options.map(({ value, label }) => ({
            id: Number(value),
            name: label,
          })),
        },
      };
      setFieldValue("wheel_vehicles", newWheelVehicles);
    },
    [],
  );

  const removeVehicleModel = useCallback(
    (index: number, id: number, setFieldValue: SetFieldValueType, values: UpdateWheelData) => {
      setFieldValue(
        `wheel_vehicles.${index}.vehicle.models`,
        values.wheel_vehicles[index]?.vehicle?.models.filter(({ id: modelId }) => modelId !== id),
      );
    },
    [],
  );

  const onManuallyAddTireSizeHandler = useCallback(
    (name: string) => {
      if (name) {
        dispatch(
          WheelsActions.createTireSize.request({
            name,
            callback: (tireSize: TireSize) => {
              formikRef.current?.setFieldValue("rim.tire_sizes", [
                ...formikRef.current.values.rim.tire_sizes,
                { id: tireSize.id, name: tireSize.name },
              ]);
            },
          }),
        );
      }
    },
    [dispatch],
  );

  const onManuallyAddVehicleHandler = useCallback(
    (name: string) => {
      if (name && formikRef.current) {
        const currentValues = formikRef.current.values;
        dispatch(
          WheelsActions.createVehicle.request({
            name,
            callback: (vehicle: Vehicle) => {
              const lastElement = currentValues.wheel_vehicles.length - 1;
              if (!currentValues.wheel_vehicles[lastElement].vehicle?.name) {
                formikRef.current?.setFieldValue("wheel_vehicles", [
                  ...currentValues.wheel_vehicles.slice(0, lastElement),
                  {
                    ...currentValues.wheel_vehicles[lastElement],
                    vehicle,
                  },
                ]);
              } else {
                formikRef.current?.setFieldValue("wheel_vehicles", [
                  ...currentValues.wheel_vehicles,
                  { ...vehicle, models: [] },
                ]);
              }
            },
          }),
        );
      }
    },
    [dispatch],
  );

  const onManuallyAddVehicleModelHandler = useCallback(
    (name: string, vehicle_id: number | undefined, vehicleIndex: number) => {
      if (name && vehicle_id && formikRef?.current) {
        const currentValue = formikRef.current.values;
        dispatch(
          WheelsActions.createVehicleModel.request({
            vehicle_id,
            name,
            callback: (vehicleModel: VehicleModel) => {
              const currentVehicleModels = currentValue.wheel_vehicles[vehicleIndex].vehicle?.models || [];
              const fieldName = `wheel_vehicles.${vehicleIndex}.vehicle.models`;
              formikRef.current?.setFieldValue(fieldName, [...currentVehicleModels, vehicleModel]);
            },
          }),
        );
      }
    },
    [dispatch],
  );

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(prepareSubmitValues(values, wheel.id));
        setSubmitting(false);
        setIsFormChanged(false);
      }}
      enableReinitialize={true}
      innerRef={formikRef}
      validateOnBlur={false}
      validationSchema={createValidationSchema}
      validateOnChange={true}
      validate={() => setIsFormChanged(true)}
    >
      {({ values, handleSubmit, setFieldValue, isValid }) => (
        <Box h="100%" maxHeight="100%" overflowY="scroll">
          <form onSubmit={handleSubmit}>
            <WheelEditFormHeader wheel={wheel} isFormValid={isValid} onBackBtnClick={onCancelAndBackBtnClick} />
            <VStack
              alignItems="flex-start"
              divider={<Divider />}
              spacing="blockMargin"
              px="blockMargin"
              pb="blockMargin"
            >
              <Flex gap="16px" alignItems="center">
                <AsyncMultipleSelect<TireSize>
                  isMulti
                  isSearchable
                  width="300px"
                  label="Tire Size"
                  options={tireSizeOptions}
                  selected={getSelectedTireSizes(values)}
                  setSelected={(option) => updateTierSizes(option, values)}
                  isRequired
                  onAddItem={onManuallyAddTireSizeHandler}
                  addItemModalType="Tire Size"
                  getData={getTireSizesOptions}
                  selectData={WheelsSelectors.selectTireSizes}
                  prepareOptionFunction={prepareTireSizeToOption}
                />
                <Box mt="23px">
                  <BadgesList
                    options={values.rim.tire_sizes.map((ts) => ({ value: ts.id, label: ts.name }))}
                    onRemove={removeTierSize}
                  />
                </Box>
              </Flex>
              <Box w="full">
                <Heading
                  as="h4"
                  fontSize="12px"
                  color="brand.secondarySubtitle"
                  letterSpacing="1.2px"
                  fontWeight="600"
                  mb="16px"
                >
                  VEHICLE DETAILS
                </Heading>
                <FieldArray
                  name="wheel_vehicles"
                  render={(arrayHelpers) => (
                    <>
                      <VStack spacing="16px">
                        {values.wheel_vehicles.map((wheelVehicle, i) => (
                          <VehicleDetailsBlock
                            key={i}
                            usedVehicleIds={getUsedVehicleIds(values)}
                            vehicles={vehicles}
                            vehicleModels={
                              wheelVehicle?.vehicle
                                ? vehicles.find((v) => v.id === wheelVehicle.vehicle?.id)?.models || []
                                : []
                            }
                            wheelVehicle={wheelVehicle}
                            setVehicle={(option: Option<string>) => setVehicle(i, option, setFieldValue, values)}
                            removeVehicle={i !== 0 ? () => arrayHelpers.remove(i) : undefined}
                            setVehicleModels={(options) => setVehicleModels(i, options, values, setFieldValue)}
                            removeVehicleModel={(id) => removeVehicleModel(i, id, setFieldValue, values)}
                            onManuallyAddVehicleHandler={onManuallyAddVehicleHandler}
                            onManuallyAddVehicleModelHandler={(name, vehicleId) =>
                              onManuallyAddVehicleModelHandler(name, vehicleId, i)
                            }
                          />
                        ))}
                      </VStack>
                      <Button
                        variant="ghost"
                        p={0}
                        h="min"
                        color="brand.primary"
                        mt="24px"
                        onClick={() => arrayHelpers.push({ vehicle_models: [] })}
                      >
                        + Add Vehicle
                      </Button>
                    </>
                  )}
                />
              </Box>
              <Button variant="ghost" leftIcon={<Delete />} p={0} h="min" onClick={onRemove}>
                Remove from Search
              </Button>
            </VStack>
          </form>
        </Box>
      )}
    </Formik>
  );
};

export default WheelEdit;
