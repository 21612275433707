import React from "react";
import { Badge, Flex, Heading, Text } from "@chakra-ui/react";
import { Wheel } from "@shared/models";

export interface WheelViewGeneralBlockProps {
  blockTitle?: string;
  wheel: Wheel;
}

const WheelViewGeneralBlock: React.FC<WheelViewGeneralBlockProps> = ({ wheel, blockTitle }) => {
  return (
    <>
      <Heading as="h4" fontSize="12px" color="brand.secondarySubtitle" letterSpacing="1.2px" fontWeight="600">
        {blockTitle}
      </Heading>
      <Text fontSize="14px" color="brand.secondarySubtitle" my="blockMargin">
        Tire Size
      </Text>
      <Flex gap="8px" flexWrap="wrap">
        {wheel.rim?.tire_sizes?.map((size) => (
          <Badge key={size.id} variant="readOnlyProp">
            {size.name}
          </Badge>
        ))}
      </Flex>
    </>
  );
};

export default WheelViewGeneralBlock;
