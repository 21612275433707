const colors = {
  brand: {
    bg: "#F1F1F1",
    primary: "#46BA90",
    secondary: "#A71C27",
    white: "#FFFFFF",
    cancel: "#F1F1F1",
    checkMark: "#46BA90",
    secondarySubtitle: "#7E7E7E",
    label: "#7E7E7E",
    sidebarBackground: "#202020",
    sidebarItemSelected: "#ACACAC",
    inputFocusBorder: "#7E7E7E",
    black: "#171717",
  },
  gray: {
    40: "#F1F1F1",
    50: "#E7E7E7",
    60: "#EDEDED",
    100: "#F1F1F1",
  },
} as const;

export type Colors = typeof colors;
export default colors;
