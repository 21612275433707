export const FONT_WEIGHT = {
  BOLD: 750,
  HEAVY: 600,
  NORMAL: 400,
};

const textStyles = {
  label: {
    fontWeight: FONT_WEIGHT.NORMAL,
    fontSize: "14px",
    color: "brand.label",
    paddingLeft: "15px",
  },
  title: {
    fontSize: "30px",
    fontWeight: FONT_WEIGHT.HEAVY,
    lineHeight: "16px",
    color: "brand.black",
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "16px",
    color: "brand.black",
  },
  secondarySubtitle: {
    fontSize: "16px",
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "16px",
    color: "brand.secondarySubtitle",
  },
  noMatchesTitle: {
    fontSize: "20px",
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "28px",
    color: "brand.secondarySubtitle",
  },
};

export type TextStyles = typeof textStyles;

export default textStyles;
