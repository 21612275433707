export enum WheelsActionTypes {
  GET_WHEELS_LIST = "@@WHEELS/GET_WHEELS_LIST",
  GET_WHEELS_LIST_SUCCESS = "@@WHEELS/GET_WHEELS_LIST_SUCCESS",
  GET_WHEELS_LIST_FAILURE = "@@WHEELS/GET_WHEELS_LIST_FAILURE_FAILURE",

  GET_WHEELS_ADVANCED_FILTER_DATA = "@@WHEELS/GET_WHEELS_ADVANCED_FILTER_DATA",
  GET_WHEELS_ADVANCED_FILTER_DATA_SUCCESS = "@@WHEELS/GET_WHEELS_ADVANCED_FILTER_DATA_SUCCESS",
  GET_WHEELS_ADVANCED_FILTER_DATA_FAILURE = "@@WHEELS/GET_WHEELS_ADVANCED_FILTER_DATA_FAILURE",

  GET_WHEEL = "@@WHEELS/GET_WHEEL",
  GET_WHEEL_SUCCESS = "@@WHEELS/GET_WHEEL_SUCCESS",
  GET_WHEEL_FAILURE = "@@WHEELS/GET_WHEEL_FAILURE",
  GET_WHEEL_CLEAR = "@@WHEELS/GET_WHEEL_CLEAR",

  GET_TIRE_SIZES = "@@WHEELS/GET_TIRE_SIZES",
  GET_TIRE_SIZES_SUCCESS = "@@WHEELS/GET_TIRE_SIZES_SUCCESS",
  GET_TIRE_SIZES_FAILURE = "@@WHEELS/GET_TIRE_SIZES_FAILURE",
  GET_TIRE_SIZES_CLEAR = "@@WHEELS/GET_TIRE_SIZES_CLEAR",

  GET_VEHICLES = "@@WHEELS/GET_VEHICLES",
  GET_VEHICLES_SUCCESS = "@@WHEELS/GET_VEHICLES_SUCCESS",
  GET_VEHICLES_FAILURE = "@@WHEELS/GET_VEHICLES_FAILURE",
  GET_VEHICLES_CLEAR = "@@WHEELS/GET_VEHICLES_CLEAR",

  CREATE_TIRE_SIZE = "@@WHEELS/CREATE_TIRE_SIZE",
  CREATE_TIRE_SIZE_SUCCESS = "@@WHEELS/CREATE_TIRE_SIZE_SUCCESS",
  CREATE_TIRE_SIZE_FAILURE = "@@WHEELS/CREATE_TIRE_SIZE_FAILURE",
  CREATE_TIRE_SIZE_CLEAR = "@@WHEELS/CREATE_TIRE_SIZE_CLEAR",

  CREATE_VEHICLE = "@@WHEELS/CREATE_VEHICLE",
  CREATE_VEHICLE_SUCCESS = "@@WHEELS/CREATE_VEHICLE_SUCCESS",
  CREATE_VEHICLE_FAILURE = "@@WHEELS/CREATE_VEHICLE_FAILURE",
  CREATE_VEHICLE_CLEAR = "@@WHEELS/CREATE_VEHICLE_CLEAR",

  CREATE_VEHICLE_MODEL = "@@WHEELS/CREATE_VEHICLE_MODEL",
  CREATE_VEHICLE_MODEL_SUCCESS = "@@WHEELS/CREATE_VEHICLE_MODEL_SUCCESS",
  CREATE_VEHICLE_MODEL_FAILURE = "@@WHEELS/CREATE_VEHICLE_MODEL_FAILURE",
  CREATE_VEHICLE_MODEL_CLEAR = "@@WHEELS/CREATE_VEHICLE_MODEL_CLEAR",

  UPDATE_WHEEL = "@@WHEELS/UPDATE_WHEEL",
  UPDATE_WHEEL_SUCCESS = "@@WHEELS/UPDATE_WHEEL_SUCCESS",
  UPDATE_WHEEL_FAILURE = "@@WHEELS/UPDATE_WHEEL_FAILURE",

  ADD_WHEEL_PICTURES = "@@WHEELS/ADD_WHEEL_PICTURES",
  ADD_WHEEL_PICTURES_SUCCESS = "@@WHEELS/ADD_WHEEL_PICTURES_SUCCESS",
  ADD_WHEEL_PICTURES_FAILURE = "@@WHEELS/ADD_WHEEL_PICTURES_FAILURE",

  DELETE_WHEEL = "@@WHEELS/DELETE_WHEEL",
  DELETE_WHEEL_SUCCESS = "@@WHEELS/DELETE_WHEEL_SUCCESS",
  DELETE_WHEEL_FAILURE = "@@WHEELS/DELETE_WHEEL_FAILURE",

  DELETE_WHEEL_PICTURE = "@@WHEELS/DELETE_WHEEL_PICTURE",
  DELETE_WHEEL_PICTURE_SUCCESS = "@@WHEELS/DELETE_WHEEL_PICTURE_SUCCESS",
  DELETE_WHEEL_PICTURE_FAILURE = "@@WHEELS/DELETE_WHEEL_PICTURE_FAILURE",

  CLEAR_WHEELS_LIST = "@@WHEELS/CLEAR_WHEELS_LIST",
  SET_FILTER = "@@WHEELS/SET_FILTER",
}
