import { ActionWithPayload, PaginatedResponse, WheelsFilter } from "@shared/interfaces";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { hideModal, navigate, startLoading, stopLoading } from "@shared/store/actions";
import { WheelAdvancedFilterDataInterface, TireSize, Vehicle, Wheel } from "@shared/models";
import {
  createTireSize,
  createVehicle,
  createVehicleModel,
  deleteWheel,
  getTireSizes,
  getVehicles,
  getWheel,
  getWheelsList,
  updateWheel,
  getWheelsAdvancedFilterData,
  addWheelPictures,
  deleteWheelPicture,
} from "@containers/Wheels/store/actions";
import { getFilter } from "@containers/Wheels/store/selectors";
import { NameOfChildRoutes, NamesOfParentRoutes } from "@shared/constants";
import {
  CreateTireSizeSuccess,
  CreateVehicleModelSuccess,
  CreateVehicleSuccess,
  UpdateWheelSuccess,
} from "@containers/Wheels/interface";
import { generatePath } from "react-router-dom";

import api from "../api";

function* getWheelsListSaga({ payload }: ActionWithPayload<WheelsFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Wheel> = yield call(api.getWheelsList, payload);
    yield put(
      getWheelsList.success({
        ...response,
        clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getWheelsList.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getWheelsAdvancedFilterDataSaga() {
  try {
    yield put(startLoading());
    const response: WheelAdvancedFilterDataInterface = yield call(api.getWheelsAdvancedFilterData);
    yield put(getWheelsAdvancedFilterData.success(response));
  } catch (error) {
    yield put(getWheelsAdvancedFilterData.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getWheelSaga({ payload }: ReturnType<typeof getWheel.request>) {
  try {
    yield put(startLoading());
    const wheel: Wheel = yield call(api.getWheel, payload);
    yield put(getWheel.success(wheel));
  } catch (error) {
    yield put(getWheel.failure(error as Error));
    yield put(navigate(NamesOfParentRoutes.WHEELS));
  } finally {
    yield put(stopLoading());
  }
}

function* getTireSizesSaga({ payload }: ReturnType<typeof getTireSizes.request>) {
  try {
    yield put(startLoading());
    const tireSizes: PaginatedResponse<TireSize> = yield call(api.getTireSizes, payload);
    yield put(getTireSizes.success(tireSizes.rows));
  } catch (error) {
    yield put(getTireSizes.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getVehiclesSaga() {
  try {
    yield put(startLoading());
    const vehicles: PaginatedResponse<Vehicle> = yield call(api.getVehicles);
    yield put(getVehicles.success(vehicles.rows));
  } catch (error) {
    yield put(getVehicles.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* createTireSizeSaga({ payload }: ReturnType<typeof createTireSize.request>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: CreateTireSizeSuccess = yield call(api.createTireSize, rest);
    if (callback) {
      callback(response?.element);
    }
  } catch (error) {
    yield put(createTireSize.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* createVehicleSaga({ payload }: ReturnType<typeof createVehicle.request>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: CreateVehicleSuccess = yield call(api.createVehicle, rest);
    if (callback) {
      callback(response?.element);
    }
    yield put(getVehicles.request());
  } catch (error) {
    yield put(createVehicle.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* createVehicleModelSaga({ payload }: ReturnType<typeof createVehicleModel.request>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: CreateVehicleModelSuccess = yield call(api.createVehicleModel, rest);
    yield put(getVehicles.request());
    if (callback) {
      callback(response?.element, payload.vehicle_id);
    }
  } catch (error) {
    yield put(createTireSize.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateWheelSaga({ payload }: ReturnType<typeof updateWheel.request>) {
  try {
    yield put(startLoading());
    const wheel: UpdateWheelSuccess = yield call(api.updateWheel, payload);
    yield put(updateWheel.success(wheel));
    yield put(
      navigate(
        generatePath(`${NamesOfParentRoutes.WHEELS}${NameOfChildRoutes.WHEELS.VIEW_ITEM}`, { id: wheel.element.id }),
      ),
    );
  } catch (error) {
    yield put(updateWheel.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* addWheelPicturesSaga({ payload }: ReturnType<typeof addWheelPictures.request>) {
  try {
    const { id, ...rest } = payload;
    yield put(startLoading());
    const wheel: UpdateWheelSuccess = yield call(api.addWheelPictures, id, rest);
    yield put(addWheelPictures.success(wheel));
    yield put(hideModal());
  } catch (error) {
    yield put(addWheelPictures.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* deleteWheelSaga({ payload }: ReturnType<typeof deleteWheel.request>) {
  try {
    yield put(startLoading());
    yield call(api.deleteWheel, payload);
    yield put(deleteWheel.success());
    const filter: WheelsFilter = yield select(getFilter());
    yield put(getWheelsList.request(filter));
    yield put(navigate(`${NamesOfParentRoutes.WHEELS}${NameOfChildRoutes.WHEELS.LIST}`));
  } catch (error) {
    yield put(deleteWheel.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* deleteWheelPictureSaga({ payload }: ReturnType<typeof deleteWheelPicture.request>) {
  try {
    yield put(startLoading());
    const wheel: UpdateWheelSuccess = yield call(api.deleteWheelPicture, payload);
    yield put(updateWheel.success(wheel));
    yield put(deleteWheelPicture.success());
  } catch (error) {
    yield put(deleteWheelPicture.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* wheelsSaga() {
  yield takeLatest(getWheelsList.request, getWheelsListSaga);
  yield takeLatest(getWheelsAdvancedFilterData.request, getWheelsAdvancedFilterDataSaga);
  yield takeLatest(getWheel.request, getWheelSaga);
  yield takeLatest(getTireSizes.request, getTireSizesSaga);
  yield takeLatest(getVehicles.request, getVehiclesSaga);
  yield takeLatest(createTireSize.request, createTireSizeSaga);
  yield takeLatest(createVehicle.request, createVehicleSaga);
  yield takeLatest(createVehicleModel.request, createVehicleModelSaga);
  yield takeLatest(updateWheel.request, updateWheelSaga);
  yield takeLatest(deleteWheel.request, deleteWheelSaga);
  yield takeLatest(deleteWheelPicture.request, deleteWheelPictureSaga);
  yield takeLatest(addWheelPictures.request, addWheelPicturesSaga);
}

export default wheelsSaga;
