import { FieldItem } from "@shared/components/FormGenerator";
import { FieldItemType, RenderTypes } from "@shared/interfaces";
import { TireSize, Vehicle, WheelAdvancedFilterDataInterface } from "@shared/models";
import { actions as WheelsAction, selectors as WheelsSelectors } from "@containers/Wheels/store";

const getOptions = (field: string, wheelAdvancedFilterDataInterface: WheelAdvancedFilterDataInterface | undefined) => {
  if (!wheelAdvancedFilterDataInterface) return [];
  const items: (string | number)[] = wheelAdvancedFilterDataInterface[field as keyof WheelAdvancedFilterDataInterface];

  return items.map((i) => ({ label: String(i), value: String(i) }));
};

const getVehiclesOptions = (vehicles: Vehicle[] | null) => {
  if (!vehicles) return [];

  return vehicles.map((v) => ({ label: String(v.name), value: String(v.id) }));
};

const getVehicleModelsOptions = (vehicleId: number | undefined, vehicles: Vehicle[] | null) => {
  if (!vehicleId || !vehicles) return [];

  const vehicle = vehicles.find((v) => v.id === Number(vehicleId));
  if (!vehicle) return [];

  return vehicle.models.map((v) => ({ label: String(v.name), value: String(v.id) }));
};

const prepareTireSizeToOption = (v: TireSize) => ({ label: String(v.name), value: String(v.id) });

export const handlers = {
  tire_size_id: {
    getData: (search: { search: string }) => {
      return WheelsAction.getTireSizes.request({
        search: search.search,
        page: 0,
        limit: 20,
      });
    },
    selectData: WheelsSelectors.selectTireSizes,
    prepareOptionFunction: prepareTireSizeToOption,
  },
};

export const getFields = (
  vehicleId: number | undefined,
  wheelAdvancedFilterDataInterface: WheelAdvancedFilterDataInterface | undefined,
  vehicles: Vehicle[] | null,
): FieldItem[] => [
  {
    type: FieldItemType.RENDER,
    renderType: RenderTypes.FIELD_LIST_LINE,
    wrapperClass: "user-name-line",
    name: "line1",
    fields: [
      {
        type: FieldItemType.SELECT,
        name: "vehicle_id",
        label: "Vehicle Make/Mfg",
        options: getVehiclesOptions(vehicles),
        isClearable: true,
      },
    ],
  },
  {
    type: FieldItemType.RENDER,
    renderType: RenderTypes.FIELD_LIST_LINE,
    wrapperClass: "user-name-line",
    name: "line2",
    fields: [
      {
        type: FieldItemType.SELECT,
        name: "vehicle_model_id",
        label: "Vehicle Model",
        options: getVehicleModelsOptions(vehicleId, vehicles),
        isClearable: true,
      },
    ],
  },
  {
    type: FieldItemType.RENDER,
    renderType: RenderTypes.FIELD_LIST_LINE,
    wrapperClass: "user-name-line",
    name: "line3",
    fields: [
      {
        type: FieldItemType.AUTOCOMPLETE_SELECT,
        name: "tire_size_id",
        label: "Tire Size",
        options: [],
        isClearable: true,
      },
      {
        type: FieldItemType.SELECT,
        name: "rim_size",
        label: "Rim Size (single piece) D x W",
        options: getOptions("rim_sizes", wheelAdvancedFilterDataInterface),
        isClearable: true,
      },
    ],
  },
  {
    type: FieldItemType.RENDER,
    renderType: RenderTypes.FIELD_LIST_LINE,
    wrapperClass: "user-name-line",
    name: "line4",
    fields: [
      {
        type: FieldItemType.SELECT,
        name: "base_size",
        label: "Base Size (multi-piece) D x W",
        options: getOptions("base_sizes", wheelAdvancedFilterDataInterface),
        isClearable: true,
      },
      {
        type: FieldItemType.SELECT,
        name: "base_number_of_pieces",
        label: "# of pieces",
        options: getOptions("base_number_of_pieces", wheelAdvancedFilterDataInterface),
        isClearable: true,
      },
    ],
  },
  {
    type: FieldItemType.RENDER,
    renderType: RenderTypes.FIELD_LIST_LINE,
    wrapperClass: "user-name-line",
    name: "line5",
    fields: [
      {
        type: FieldItemType.SELECT,
        name: "number_of_bolts",
        label: "Bolt Hole QTY",
        options: getOptions("number_of_bolts", wheelAdvancedFilterDataInterface),
        isClearable: true,
      },
      {
        type: FieldItemType.SELECT,
        name: "bolt_circle",
        label: "Bolt Circle",
        options: getOptions("bolt_circles", wheelAdvancedFilterDataInterface),
        isClearable: true,
      },
    ],
  },
  {
    type: FieldItemType.RENDER,
    renderType: RenderTypes.FIELD_LIST_LINE,
    wrapperClass: "user-name-line",
    name: "line7",
    fields: [
      {
        type: FieldItemType.SELECT,
        name: "bolt_diameter",
        label: "Bolt Hole Diameter",
        options: getOptions("bolt_diameters", wheelAdvancedFilterDataInterface),
        isClearable: true,
      },
      {
        type: FieldItemType.SELECT,
        name: "number_of_hand_holes",
        label: "Hand Holes QTY",
        options: getOptions("number_of_hand_holes", wheelAdvancedFilterDataInterface),
        isClearable: true,
      },
    ],
  },
  {
    type: FieldItemType.RENDER,
    renderType: RenderTypes.FIELD_LIST_LINE,
    wrapperClass: "user-name-line",
    name: "line6",
    fields: [
      {
        type: FieldItemType.SELECT,
        name: "inner_dimension",
        label: "Pilot ID/Inner Dimension",
        options: getOptions("inner_dimensios", wheelAdvancedFilterDataInterface),
        isClearable: true,
      },
      {
        type: FieldItemType.SELECT,
        name: "custom_oem",
        label: "OEM or Custom",
        options: getOptions("custom_oem", wheelAdvancedFilterDataInterface),
        isClearable: true,
      },
    ],
  },
];
