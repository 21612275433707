import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { BaseFilter, WheelsFilter } from "@shared/interfaces";
import {
  AddWheelPicturesDto,
  CreateTireSize,
  CreateVehicle,
  CreateVehicleModel,
  UpdateWheel,
} from "@containers/Wheels/interface";

export default {
  getWheelsList: (payload: WheelsFilter) => request(METHODS.GET, API.WHEELS.GET_WHEELS_LIST)({ params: payload }),
  getWheelsAdvancedFilterData: () => request(METHODS.GET, API.WHEELS.GET_WHEELS_ADVANCED_FILTER_DATA)(),
  getWheel: (id: number) => request(METHODS.GET, API.WHEELS.GET_WHEEL(id))(),
  getTireSizes: (filter: BaseFilter) => request(METHODS.GET, API.WHEELS.GET_TIRE_SIZES)({ params: filter }),
  getVehicles: () => request(METHODS.GET, API.WHEELS.GET_VEHICLES)(),
  getVehicleModels: (id: number) =>
    request(METHODS.GET, API.WHEELS.GET_VEHICLE_MODELS(id))({ params: { page: 0, limit: 100 } }),
  createTireSize: (payload: CreateTireSize) => request(METHODS.POST, API.WHEELS.GET_TIRE_SIZES)(payload),
  createVehicle: (payload: CreateVehicle) => request(METHODS.POST, API.WHEELS.GET_VEHICLES)(payload),
  createVehicleModel: ({ vehicle_id, ...rest }: CreateVehicleModel) =>
    request(METHODS.POST, API.WHEELS.CREATE_VEHICLE_MODEL(vehicle_id))(rest),
  updateWheel: ({ id, ...rest }: UpdateWheel) => request(METHODS.PUT, API.WHEELS.UPDATE_WHEEL(id))(rest),
  deleteWheel: (id: number) => request(METHODS.DELETE, API.WHEELS.DELETE_WHEEL(id))(),
  deleteWheelPicture: (payload: { wheel_id: number; picture_id: number }) => {
    const { wheel_id, picture_id } = payload;
    return request(METHODS.DELETE, API.WHEELS.DELETE_WHEEL_PICTURE(wheel_id, picture_id))();
  },
  addWheelPictures: (id: number, payload: AddWheelPicturesDto) =>
    request(METHODS.POST, API.WHEELS.ADD_WHEEL_PICTURES(id))(payload),
};
