import config from "../../config";

export default {
  AUTH: {
    LOGIN: `${config.baseApiUrl}auth/login`,
  },
  USER: {
    GET_USER_INFO: `${config.baseApiUrl}users/details`,
  },
  WHEELS: {
    GET_WHEELS_LIST: `${config.baseApiUrl}wheels`,
    GET_WHEELS_ADVANCED_FILTER_DATA: `${config.baseApiUrl}wheels/advanced-filter-data`,
    GET_WHEEL: (id: number) => `${config.baseApiUrl}wheels/${id}`,
    GET_TIRE_SIZES: `${config.baseApiUrl}tire-sizes`,
    GET_VEHICLES: `${config.baseApiUrl}vehicles`,
    GET_VEHICLE_MODELS: (id: number) => `${config.baseApiUrl}vehicles/${id}/models`,
    CREATE_TIRE_SIZE: `${config.baseApiUrl}tire-sizes`,
    CREATE_VEHICLE: `${config.baseApiUrl}vehicles`,
    CREATE_VEHICLE_MODEL: (id: number) => `${config.baseApiUrl}vehicles/${id}/models`,
    UPDATE_WHEEL: (id: number) => `${config.baseApiUrl}wheels/${id}`,
    ADD_WHEEL_PICTURES: (id: number) => `${config.baseApiUrl}wheels/${id}/pictures`,
    DELETE_WHEEL: (id: number) => `${config.baseApiUrl}wheels/${id}`,
    DELETE_WHEEL_PICTURE: (wheel_id: number, picture_id: number) =>
      `${config.baseApiUrl}wheels/${wheel_id}/pictures/${picture_id}`,
  },
};
