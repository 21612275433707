import { Box } from "@chakra-ui/react";
import React, { FC } from "react";

export interface WheelViewItemWrapperProps {
  width?: string;
  children: React.ReactNode;
}

const WheelViewItemWrapper: FC<WheelViewItemWrapperProps> = (props) => {
  const { children, width } = props;
  return (
    <Box
      w={width}
      display="flex"
      flexDirection="column"
      bg="brand.white"
      borderRadius="table"
      border="1px solid  #E7E7E7"
      boxShadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)"
      p="16px"
      minHeight="calc(96vh - 80px)"
      maxHeight="calc(96vh - 80px)"
      overflowY="auto"
    >
      {children}
    </Box>
  );
};

export default WheelViewItemWrapper;
