import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { WheelsFilter } from "@shared/interfaces";
import { WheelsStateType } from "@containers/Wheels/interface";

import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const defaultFilter: WheelsFilter = {
  page: 0,
  limit: 100,
  search: "",
  is_available_on_stock: true,
};

export const initialState: WheelsStateType = {
  wheels: [],
  wheelsAdvancedFilterData: undefined,
  wheelsTotal: 0,
  filter: { ...defaultFilter },
  wheel: null,
  tireSizes: null,
  vehicles: null,
};

const reducer = createReducer<WheelsStateType, Action>(initialState)
  .handleAction(actions.getWheelsList.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.wheels = !clear ? [...nextState.wheels, ...rows] : [...rows];
      nextState.wheelsTotal = count;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.clearWheelsList, (state) =>
    produce(state, (nextState) => {
      nextState.wheels = [];
      nextState.wheelsTotal = 0;
    }),
  )
  .handleAction(actions.getWheel.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.wheel = action.payload;
    }),
  )
  .handleAction(actions.addWheelPictures.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.wheel = action.payload.element;
    }),
  )
  .handleAction(actions.getWheelsAdvancedFilterData.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.wheelsAdvancedFilterData = action.payload;
    }),
  )
  .handleAction(actions.getWheel.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.wheel = null;
    }),
  )
  .handleAction(actions.getTireSizes.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.tireSizes = action.payload;
    }),
  )
  .handleAction(actions.getTireSizes.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.tireSizes = null;
    }),
  )
  .handleAction(actions.getVehicles.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.vehicles = action.payload;
    }),
  )
  .handleAction(actions.getVehicles.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.vehicles = null;
    }),
  )
  .handleAction(actions.updateWheel.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.wheel = action.payload.element;
    }),
  )
  .handleAction(actions.deleteWheel.success, (state) =>
    produce(state, (nextState) => {
      nextState.wheel = null;
    }),
  );

export { reducer as WheelsReducer };
