import { Box, ButtonGroup, Flex, Heading } from "@chakra-ui/react";
import { Button } from "@shared/components";
import React, { FC } from "react";
import { Wheel } from "@shared/models";
import { ReactComponent as Update } from "@assets/files/icons/general/option-selected.svg";
import { ReactComponent as BackButton } from "@assets/files/icons/general/back.svg";

export interface WheelEditFormHeaderProps {
  wheel?: Wheel;
  isFormValid?: boolean;
  onBackBtnClick?: () => void;
}

const wheelEditFormHeader: FC<WheelEditFormHeaderProps> = (props) => {
  const { wheel, isFormValid, onBackBtnClick } = props;

  return (
    <Flex alignItems="center" justifyContent="space-between" p="blockMargin">
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Button variant="ghost" p={0} leftIcon={<BackButton />} onClick={onBackBtnClick} />
        <Heading size="md">{wheel?.part_number ? `Edit ${wheel.part_number}` : "Loading..."}</Heading>
      </Box>
      <ButtonGroup gap="2">
        <Button variant="cancel" onClick={onBackBtnClick}>
          Cancel
        </Button>
        <Button variant="primary" leftIcon={<Update />} type="submit" isDisabled={!isFormValid}>
          Update
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default wheelEditFormHeader;
