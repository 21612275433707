export const availabilityDropdownOptions = [
  { value: "Available on stock", label: "Available on stock" },
  { value: "Not available on stock", label: "Not available on stock" },
  { value: "All", label: "All" },
];

export enum WHEEL_PICTURE_TYPE {
  VEHICLE = "Vehicle",
  WHEEL = "Wheel",
  TIRE = "Tire",
  AUTOCAD_DRAWING = "Autocad Drawing",
}

export const wheelPictureTypeDropdownOptions = [
  {
    label: WHEEL_PICTURE_TYPE.WHEEL,
    value: WHEEL_PICTURE_TYPE.WHEEL,
  },
  {
    label: WHEEL_PICTURE_TYPE.VEHICLE,
    value: WHEEL_PICTURE_TYPE.VEHICLE,
  },
  {
    label: WHEEL_PICTURE_TYPE.TIRE,
    value: WHEEL_PICTURE_TYPE.TIRE,
  },
  {
    label: WHEEL_PICTURE_TYPE.AUTOCAD_DRAWING,
    value: WHEEL_PICTURE_TYPE.AUTOCAD_DRAWING,
  },
];
