import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { MultiValue, Select, SingleValue } from "chakra-react-select";
import { Option, Option as SelectOption } from "@shared/interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
  chakraStyles,
  getCustomCheckboxesComponents,
  getCustomComponents,
} from "@shared/components/Field/MultipleSelect/multipleSelectHelpers";
import { useDebounce } from "@shared/hooks";
import { ThirdPartyHandler } from "@shared/components";

import { MultipleSelectProps } from "../MultipleSelect/MultipleSelect";

export interface AsyncMultipleSelectProps<T> extends MultipleSelectProps, ThirdPartyHandler {
  prepareOptionFunction?: (data: T) => Option<string>;
}

const MultipleSelect = <T,>(props: AsyncMultipleSelectProps<T>) => {
  const {
    isMulti,
    label,
    selected,
    setSelected,
    isSearchable,
    width,
    isRequired,
    onAddItem,
    addItemModalType,
    getData,
    selectData,
    prepareOptionFunction,
  } = props;
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const debouncedSearch = useDebounce(inputValue, 500);

  const onChange = useCallback(
    (options: SingleValue<SelectOption<string>> | MultiValue<SelectOption<string>>) => {
      setSelected(Array.isArray(options) ? [...options] : [options]);
    },
    [setSelected],
  );

  const customCheckboxesComponents = useMemo(() => {
    return getCustomCheckboxesComponents(onAddItem, addItemModalType, dispatch);
  }, [addItemModalType, dispatch, onAddItem]);

  const customComponents = useMemo(() => {
    return getCustomComponents(onAddItem, addItemModalType, dispatch);
  }, [addItemModalType, dispatch, onAddItem]);

  const suggestionData: T[] = useSelector(selectData());

  const options = useMemo(() => {
    if (!prepareOptionFunction || !suggestionData) return [];
    return suggestionData.map(prepareOptionFunction) as Option<string>[];
  }, [prepareOptionFunction, suggestionData]);

  useEffect(() => {
    if (debouncedSearch || !options.length) {
      dispatch(getData({ search: debouncedSearch }));
    }
  }, [debouncedSearch, dispatch, getData, options.length]);

  return (
    <Box w={width || "full"} flexShrink={0}>
      <Text color="brand.secondarySubtitle" ml="16px" mb="3px" fontSize="14px">
        {label}&nbsp;
        {isRequired && (
          <Text as="span" color="brand.secondary">
            *
          </Text>
        )}
      </Text>
      <Select
        isMulti={isMulti}
        placeholder="Select"
        isSearchable={isSearchable}
        options={options}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        isClearable={false}
        controlShouldRenderValue={!isMulti}
        value={selected}
        onChange={onChange}
        components={isMulti ? customCheckboxesComponents : customComponents}
        chakraStyles={chakraStyles}
        onInputChange={(value) => setInputValue(value)}
      />
    </Box>
  );
};

export default MultipleSelect;
