export const NamesOfParentRoutes = {
  AUTH: "/auth",
  WHEELS: "/wheels",
};

export const NameOfRoutes = {
  SIGN_IN: "/",
  AUTH: "/auth/",
  AUTH_LOGIN: "/auth/login",
};

export const NameOfChildRoutes = {
  AUTH: {
    LOGIN: "/login",
  },
  WHEELS: {
    LIST: "",
    VIEW_ITEM: "/:id",
    EDIT_ITEM: "/:id/edit",
  },
  DEFAULT_REDIRECT: "/*",
};
