import React, { FC, useCallback, useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Table from "@shared/components/Table/Table";
import { actions as sharedActions } from "@shared/store";
import { WheelsListHeader } from "@containers/Wheels/components";
import { wheelsTableProperties } from "@containers/Wheels/constants";
import { Wheel } from "@shared/models";
import { actions as WheelsAction, selectors as WheelsSelectors } from "@containers/Wheels/store";
import { useDispatch, useSelector } from "react-redux";
import { WheelsFilter } from "@shared/interfaces";
import { EmptyState } from "@shared/components";
import { NameOfChildRoutes, NamesOfParentRoutes, OVERLAY_TYPES } from "@shared/constants";

const WheelsListContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const wheels = useSelector(WheelsSelectors.getWheelsList());
  const wheelsTotal = useSelector(WheelsSelectors.getWheelsTotal());
  const filter = useSelector(WheelsSelectors.getFilter());

  const changeFilter = useCallback(
    (updatedFilter: Partial<WheelsFilter>) => {
      dispatch(
        WheelsAction.setFilter({
          ...filter,
          ...updatedFilter,
        }),
      );
    },
    [dispatch, filter],
  );

  const handleNextPage = useCallback(() => {
    if (filter.limit * (filter.page + 1) < wheelsTotal) {
      changeFilter({ page: filter.page + 1 });
    }
  }, [changeFilter, filter.limit, filter.page, wheelsTotal]);

  const handleTableRowClick = useCallback(
    (item: Wheel) => {
      navigate(generatePath(`${NamesOfParentRoutes.WHEELS}${NameOfChildRoutes.WHEELS.VIEW_ITEM}`, { id: item.id }));
    },
    [navigate],
  );

  const handleChangeSearch = useCallback(
    (search: string) => {
      changeFilter({ search, page: 0 });
    },
    [changeFilter],
  );

  const handleChangeAvailability = useCallback(
    (isAvailableOnStock: boolean | undefined) => {
      changeFilter({ is_available_on_stock: isAvailableOnStock });
    },
    [changeFilter],
  );

  const handleOpenAdvancedFilter = useCallback(() => {
    dispatch(
      sharedActions.showOverlay({
        type: OVERLAY_TYPES.WHEELS_LIST_ADVANCED_FILTER,
        closeOnOverlayClick: false,
        props: {},
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(WheelsAction.getWheelsList.request(filter));
  }, [dispatch, filter]);

  return (
    <Box padding="0 32px" minWidth="calc(100vw - 56px)">
      <WheelsListHeader
        total={wheelsTotal}
        onChangeSearch={handleChangeSearch}
        onChangeAvailability={handleChangeAvailability}
        onOpenAdvancedFilter={handleOpenAdvancedFilter}
        filter={filter}
      />
      {wheelsTotal ? (
        <Box>
          <Table
            items={wheels}
            properties={wheelsTableProperties}
            onFinishScroll={handleNextPage}
            onRowClick={handleTableRowClick}
          />
        </Box>
      ) : (
        <EmptyState title="No matches found" />
      )}
    </Box>
  );
};

export default WheelsListContainer;
