import React, { FC, useCallback } from "react";
import { Box, Button, Icon } from "@chakra-ui/react";
import { ReactComponent as FilterIcon } from "@assets/files/icons/general/filter.svg";
import { InputSearch } from "@shared/components";
import { availabilityDropdownOptions } from "@containers/Wheels/constants";
import { Option, WheelsFilter } from "@shared/interfaces";
import SelectInput from "@shared/components/Field/Select/Select";
import { getOptionFromAvailability } from "@containers/Wheels/utils";

export interface WheelsListHeaderProps {
  total: number;
  onChangeSearch: (search: string) => void;
  onChangeAvailability: (isAvailableOnStock: boolean | undefined) => void;
  onOpenAdvancedFilter: () => void;
  filter: WheelsFilter;
}

const WheelsListHeader: FC<WheelsListHeaderProps> = (props) => {
  const { total, onChangeSearch, onChangeAvailability, filter, onOpenAdvancedFilter } = props;

  const onChangeAvailabilityHandler = useCallback(
    (val: Option<string> | null) => {
      switch (val?.value) {
        case availabilityDropdownOptions[0].value:
          onChangeAvailability(true);
          break;
        case availabilityDropdownOptions[1].value:
          onChangeAvailability(false);
          break;
        case availabilityDropdownOptions[2].value:
          onChangeAvailability(undefined);
      }
    },
    [onChangeAvailability],
  );

  return (
    <Box h="90px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" minWidth="250px">
        <Box mr="12px" textStyle="title">
          Wheels
        </Box>
        <Box textStyle="secondarySubtitle">(Total {total})</Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="16px"
        minWidth="50%"
        paddingRight="16px"
      >
        <InputSearch onChangeSearch={onChangeSearch} value={filter.search} />
        <Box w="258px" minW="258px">
          <SelectInput
            name="availbbility-select"
            onChange={onChangeAvailabilityHandler}
            options={availabilityDropdownOptions}
            value={getOptionFromAvailability(filter?.is_available_on_stock)}
          />
        </Box>

        <Button minWidth="162px" variant="ghost" onClick={onOpenAdvancedFilter}>
          <Icon boxSize="26px" color="brand.secondary" mt="6px">
            <FilterIcon />
          </Icon>
          Advanced Filters
        </Button>
      </Box>
    </Box>
  );
};

export default WheelsListHeader;
