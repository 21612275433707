import React, { FC, useCallback, useEffect } from "react";
import { actions as WheelsAction, selectors as WheelsSelectors } from "@containers/Wheels/store";
import { actions as sharedActions } from "@shared/store";
import { useDispatch, useSelector } from "react-redux";
import { WheelsFilter } from "@shared/interfaces";
import { WheelsListAdvancedFilterForm } from "@containers/Wheels/components";

interface WheelsListAdvancedFilterContainerProps {
  onClose: () => void;
}

const WheelsListAdvancedFilterContainer: FC<WheelsListAdvancedFilterContainerProps> = ({ onClose }) => {
  const dispatch = useDispatch();

  const filter = useSelector(WheelsSelectors.getFilter());
  const wheelsAdvancedFilterData = useSelector(WheelsSelectors.getWheelsAdvancedFilterData());
  const vehicles = useSelector(WheelsSelectors.selectVehicles());

  useEffect(() => {
    dispatch(WheelsAction.getWheelsAdvancedFilterData.request());
    dispatch(WheelsAction.getVehicles.request());
  }, [dispatch]);

  const handleChangeForm = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const changeFilter = useCallback(
    (updatedFilter: Partial<WheelsFilter>) => {
      dispatch(sharedActions.hideOverlay());
      dispatch(
        WheelsAction.setFilter({
          ...filter,
          ...updatedFilter,
        }),
      );
    },
    [dispatch, filter],
  );

  return (
    <WheelsListAdvancedFilterForm
      onClose={onClose}
      onSubmit={changeFilter}
      onChange={handleChangeForm}
      filter={filter}
      wheelsAdvancedFilterData={wheelsAdvancedFilterData}
      vehicles={vehicles}
    />
  );
};

export default WheelsListAdvancedFilterContainer;
