import React, { lazy, useEffect } from "react";
import { useRoutes, RouteObject, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { NameOfChildRoutes, NamesOfParentRoutes } from "@shared/constants";
import useNotification from "@shared/hooks/NotificationHook";
import { Loadable, ModalWrapper } from "@shared/components";
import { OverlayWrapper } from "@shared/components/OverlayWrapper";
import "react-image-gallery/styles/css/image-gallery.css";

import { NotFound } from "../../shared/components";
import { AuthGuard, GuestGuard } from "../../shared/guards";

const WheelsContainer = Loadable(lazy(() => import("@containers/Wheels/containers/WheelsContainer/WheelsContainer")));
const AuthContainer = Loadable(lazy(() => import("@containers/Auth/containers/AuthContainer/AuthContainer")));

const routes: RouteObject[] = [
  {
    path: `${NamesOfParentRoutes.AUTH}/*`,
    element: (
      <GuestGuard>
        <AuthContainer />
      </GuestGuard>
    ),
  },
  {
    path: `${NamesOfParentRoutes.WHEELS}/*`,
    element: (
      <AuthGuard>
        <WheelsContainer />
      </AuthGuard>
    ),
  },
  {
    path: `/`,
    element: <Navigate to={`${NamesOfParentRoutes.WHEELS}${NameOfChildRoutes.WHEELS.LIST}`} />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notification = useSelector(sharedSelectors.getNotification());
  const navigateURL = useSelector(sharedSelectors.getNavigateURL());

  useEffect(() => {
    if (navigateURL) {
      navigate(navigateURL);
      dispatch(sharedActions.navigate(""));
    }
  }, [navigateURL, dispatch, navigate]);

  const content = useRoutes(routes);
  useNotification(notification);

  return (
    <Box className="App">
      <ModalWrapper />
      <OverlayWrapper />
      {content}
    </Box>
  );
};

export default App;
