import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "@shared/store";
import { MODAL_TYPES, OVERLAY_TYPES } from "@shared/constants";
import { Drawer, DrawerOverlay, DrawerContent, DrawerBody } from "@chakra-ui/react";
import { WheelsListAdvancedFilterContainer } from "@containers/Wheels/containers/WheelsListContainer";
import { WheelViewFullscreenImage } from "@containers/Wheels/components/WheelViewContainer/WheelViewFullscreenImage";
import { OverlaySize } from "@shared/interfaces";

function OverlayWrapper() {
  const dispatch = useDispatch();

  const overlay = useSelector(selectors.getOverlay());

  const hideOverlayAction = useCallback(() => {
    dispatch(actions.hideOverlay());
    if (overlay?.props.onClose) {
      overlay.props.onClose();
    }
  }, [dispatch, overlay]);

  const onCloseOverlay = useCallback(() => {
    if (overlay?.leaveConfirm) {
      dispatch(
        actions.showModal({
          type: MODAL_TYPES.CONFIRM,
          props: {
            heading: "You have unsaved changes",
            content: "Are you sure you want to leave? If you leave this page now your progress will be lost.",
            cancelBtnText: "Cancel",
            successBtnText: "Leave",
            onSuccess: hideOverlayAction,
          },
        }),
      );
      return;
    }

    hideOverlayAction();
  }, [overlay, dispatch, hideOverlayAction]);

  const Component = useMemo(() => {
    if (!overlay) return;
    return overlayStrategy(overlay.type);
  }, [overlay]);

  return overlay ? (
    <>
      <Drawer
        isOpen={Boolean(overlay)}
        placement="right"
        onClose={onCloseOverlay}
        closeOnOverlayClick={overlay.closeOnOverlayClick}
      >
        <DrawerOverlay />
        <DrawerContent
          w={overlay?.size === OverlaySize.FULLSCREEN ? "100%" : "550px"}
          maxW={overlay?.size === OverlaySize.FULLSCREEN ? "100%" : "550px"}
          bg={overlay?.type === OVERLAY_TYPES.WHEEL_VIEW_FULLSCREEN_IMAGE ? "brand.black" : "white"}
        >
          <DrawerBody>{Component ? <Component {...overlay.props} onClose={onCloseOverlay} /> : null}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  ) : null;
}

export default OverlayWrapper;

const overlayStrategy = (type: string) => {
  switch (type) {
    case OVERLAY_TYPES.WHEELS_LIST_ADVANCED_FILTER: {
      return WheelsListAdvancedFilterContainer;
    }
    case OVERLAY_TYPES.WHEEL_VIEW_FULLSCREEN_IMAGE: {
      return WheelViewFullscreenImage;
    }
  }
};
