import { UpdateWheel, UpdateWheelData, WheelEditFormShape } from "@containers/Wheels/interface";
import { TireSize, Wheel } from "@shared/models";
import * as Yup from "yup";
import { FormikProps } from "formik";
import { actions as WheelsAction } from "@containers/Wheels/store";

export type SetFieldValueType = FormikProps<UpdateWheelData>["setFieldValue"];

export const getInitValues = (wheel: Wheel): WheelEditFormShape => ({
  rim: {
    id: wheel?.rim?.id || 0,
    tire_sizes:
      wheel?.rim?.tire_sizes.map((tireSize) => ({
        id: tireSize.id || 0,
        name: tireSize.name || "",
      })) || [],
  },
  wheel_vehicles: wheel.wheel_vehicles.map((wheelVehicle) => ({
    id: wheelVehicle.id || 0,
    vehicle: {
      id: wheelVehicle.vehicle?.id || 0,
      name: wheelVehicle.vehicle?.name || "",
      models: wheelVehicle.vehicle_models.map((vehicleModel) => ({
        id: vehicleModel.id || 0,
        name: vehicleModel.name || "",
      })),
    },
  })),
});

export const prepareSubmitValues = (values: UpdateWheelData, wheelId: number) => {
  const preparedValues: UpdateWheel = {
    id: wheelId,
    rim: {
      id: values.rim.id,
      tire_sizes: values.rim.tire_sizes.map(({ id }) => ({ id })),
    },
    wheel_vehicles: values.wheel_vehicles
      .filter(({ vehicle }) => vehicle)
      .map(({ id, vehicle }) => ({
        id,
        vehicle_id: vehicle?.id || 0,
        vehicle_models: vehicle?.models.map(({ id }) => ({ id })) || [],
      })),
  };

  return preparedValues;
};

export const createValidationSchema = Yup.object().shape({
  rim: Yup.object().shape({
    id: Yup.number(),
    tire_sizes: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          name: Yup.string(),
        }),
      )
      .min(1, "At least one tire size is required"),
  }),
  wheel_vehicles: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      vehicle: Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
        models: Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.number(),
              name: Yup.string(),
            }),
          )
          .min(1, "At least one vehicle model is required"),
      }),
    }),
  ),
});

export const getTireSizesOptions = (search: { search: string }) => {
  return WheelsAction.getTireSizes.request({
    search: search.search,
    page: 0,
    limit: 20,
  });
};

export const prepareTireSizeToOption = (v: TireSize) => ({ label: String(v.name), value: String(v.id) });
