import { Button, ModalBody, ModalFooter, Box, Input, Text, ModalHeader } from "@chakra-ui/react";
import React, { FC, useCallback, useState } from "react";

interface AddManualyItemModalProps {
  itemName: string;
  onClose: () => void;
  onSuccess: (name: string) => void;
}

const AddManualyItemModal: FC<AddManualyItemModalProps> = ({ itemName, onClose, onSuccess }) => {
  const [inputValue, setInputValue] = useState<string>("");

  const localOnSuccess = useCallback(() => {
    onSuccess(inputValue);
  }, [inputValue, onSuccess]);

  return (
    <>
      <ModalHeader>{`Add ${itemName} Manually`}</ModalHeader>
      <ModalBody>
        <Box>
          <Text color="brand.secondarySubtitle" ml="16px" mb="3px" fontSize="14px">
            {itemName}&nbsp;
            <Text as="span" color="brand.secondary">
              *
            </Text>
          </Text>
          <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} maxLength={50} />
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button variant="cancel" onClick={onClose} mr={3}>
          Cancel
        </Button>
        <Button variant="primary" onClick={localOnSuccess}>
          {`Add ${itemName}`}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddManualyItemModal;
