import React, { useEffect, useMemo, useState } from "react";
import { Select } from "@shared/components";
import { AnyType, FieldItemType, Option } from "@shared/interfaces";
import { Box, FormLabel } from "@chakra-ui/react";
import { useDebounce } from "@shared/hooks";
import { useDispatch, useSelector } from "react-redux";

import { RenderField, GenerateRenderField, Handlers } from "../../interfaces";

export interface SelectFieldItem extends RenderField {
  type: FieldItemType.SELECT;
  label?: string;
  options: Option<string>[];
  isClearable?: boolean;
}

export const GenerateAutocompleteSelect: GenerateRenderField<SelectFieldItem & Handlers> = ({
  formikProps,
  name,
  wrapperClass,
  label,
  isClearable,
  handlers,
}) => {
  const dispatch = useDispatch();
  const handlerData = handlers?.[name];

  const [inputValue, setInputValue] = useState("");
  const debouncedSearch = useDebounce(inputValue, 500);

  const suggestionData: AnyType[] = useSelector(handlerData?.selectData());

  const options = useMemo(() => {
    if (!handlerData?.prepareOptionFunction || !suggestionData) return [];
    return suggestionData.map(handlerData?.prepareOptionFunction) as Option<string>[];
  }, [handlerData, suggestionData]);

  const currentOptionValue = useMemo(() => {
    return options.find((option) => option.value === formikProps.values[name]) || null;
  }, [options, name, formikProps.values]);

  useEffect(() => {
    if ((debouncedSearch || !options.length) && handlerData) {
      dispatch(handlerData.getData({ search: debouncedSearch }));
    }
  }, [debouncedSearch, handlerData, dispatch, options.length]);

  return (
    <Box>
      <FormLabel textStyle="label">{label}</FormLabel>
      <Select
        name={name}
        options={options}
        value={currentOptionValue}
        className={wrapperClass}
        onChange={(option: Option<string> | null) => formikProps.setFieldValue(name, option?.value || null)}
        isClearable={isClearable}
        onInputChange={setInputValue}
      />
    </Box>
  );
};
