import React, { useCallback, useMemo, useState } from "react";
import { Box, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { PictureType, WheelPicture } from "@shared/models";
import { Button } from "@shared/components";
import { ReactComponent as Plus } from "@assets/files/icons/general/plus.svg";
import { CloseIcon } from "@chakra-ui/icons";
import { GalleryImage } from "@containers/Wheels/components/WheelViewContainer/WheelViewFullscreenImage/WheelViewFullscreenImage";

export interface WheelViewPicturesBlockProps {
  wheelPictures: WheelPicture[];
  onAddPictures: () => void;
  removePictureHandler: (pictureId: number) => void;
  openFullscreenGallery: (images: GalleryImage[], index: number) => void;
}

const WheelViewPicturesBlock: React.FC<WheelViewPicturesBlockProps> = (props) => {
  const { wheelPictures, onAddPictures, openFullscreenGallery, removePictureHandler } = props;

  const [hoveredPicture, setHoveredPicture] = useState<number | null>(null);
  const groupedPictures = useMemo(() => {
    const pictures: Record<PictureType, Record<number, WheelPicture>> = {
      Vehicle: {},
      Tire: {},
      Wheel: {},
      "Autocad Drawing": {},
    };

    const addedIds: Record<PictureType, Set<number>> = {
      Vehicle: new Set(),
      Tire: new Set(),
      Wheel: new Set(),
      "Autocad Drawing": new Set(),
    };

    wheelPictures.forEach((wheelPicture) => {
      const { picture_type } = wheelPicture;
      const pictureId = wheelPicture.picture.id;
      if (!addedIds[picture_type].has(pictureId)) {
        pictures[picture_type][pictureId] = wheelPicture;
        addedIds[picture_type].add(pictureId);
      }
    });

    return Object.entries(pictures)
      .map(([type, wp]) => ({ type: type as PictureType, pictures: Object.values(wp) }))
      .filter(({ pictures }) => pictures.length > 0);
  }, [wheelPictures]);

  const handleRemovePicture = useCallback(
    (pictureId: number) => {
      removePictureHandler(pictureId);
    },
    [removePictureHandler],
  );

  const handleOpenFullscreenGallery = useCallback(
    (id: number) => {
      const galleryImages = groupedPictures.flatMap(({ pictures }) =>
        pictures.map(({ picture: { id, location, resized_location } }) => ({
          original: location,
          thumbnail: resized_location,
          id,
        })),
      );

      const index = galleryImages.findIndex((image) => image.id === id);

      openFullscreenGallery(galleryImages, index);
    },
    [groupedPictures, openFullscreenGallery],
  );

  return (
    <Box w="full">
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <Heading as="h4" fontSize="12px" color="brand.secondarySubtitle" letterSpacing="1.2px" fontWeight="600">
          PICTURES
        </Heading>
        <Button variant="ghost" pr="0" height="20px" leftIcon={<Plus />} onClick={() => onAddPictures()}>
          Add Picture
        </Button>
      </Flex>
      <VStack spacing="8px" alignItems="flex-start">
        {groupedPictures.map(({ type, pictures }) => (
          <Box key={type} bg="#F9F9F9" borderRadius="8px" p="16px" w="full">
            <Text mb="10px">
              {type}
              <Text as="span" color="brand.secondarySubtitle" ml="5px">
                ({pictures.length} added)
              </Text>
            </Text>
            <Flex flexWrap="wrap" columnGap="8px" rowGap="8px">
              {pictures.map(({ picture: { id, resized_location } }) => (
                <Box key={id} position="relative">
                  <Image
                    src={resized_location}
                    w="88px"
                    h="88px"
                    objectFit="cover"
                    borderRadius="8px"
                    onMouseEnter={() => setHoveredPicture(id)}
                    onMouseLeave={() => setHoveredPicture(null)}
                    onClick={() => handleOpenFullscreenGallery(id)}
                  />
                  {hoveredPicture === id && (
                    <Box
                      position="absolute"
                      top="1px"
                      right="1px"
                      p="4px"
                      bg={`rgba(0, 0, 0, ${hoveredPicture === id ? 0.7 : 0})`}
                      borderRadius="base"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      onMouseEnter={() => setHoveredPicture(id)}
                      onMouseLeave={() => setHoveredPicture(null)}
                      onClick={() => handleRemovePicture(id)}
                      cursor="pointer"
                    >
                      <CloseIcon color="white" />
                    </Box>
                  )}
                </Box>
              ))}
            </Flex>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default WheelViewPicturesBlock;
