import { createAction, createAsyncAction } from "typesafe-actions";
import { BaseFilter, PaginatedResponse, ResponseError, WheelsFilter } from "@shared/interfaces";
import { WheelsActionTypes } from "@containers/Wheels/store/constants";
import { WheelAdvancedFilterDataInterface, TireSize, Vehicle, VehicleModel, Wheel } from "@shared/models";
import {
  AddWheelPicturesDto,
  CreateTireSize,
  CreateVehicle,
  CreateVehicleModel,
  UpdateWheel,
  UpdateWheelSuccess,
} from "@containers/Wheels/interface";

export const getWheelsList = createAsyncAction(
  WheelsActionTypes.GET_WHEELS_LIST,
  WheelsActionTypes.GET_WHEELS_LIST_SUCCESS,
  WheelsActionTypes.GET_WHEELS_LIST_FAILURE,
)<WheelsFilter, PaginatedResponse<Wheel>, ResponseError>();

export const getWheelsAdvancedFilterData = createAsyncAction(
  WheelsActionTypes.GET_WHEELS_ADVANCED_FILTER_DATA,
  WheelsActionTypes.GET_WHEELS_ADVANCED_FILTER_DATA_SUCCESS,
  WheelsActionTypes.GET_WHEELS_ADVANCED_FILTER_DATA_FAILURE,
)<undefined, WheelAdvancedFilterDataInterface | undefined, ResponseError>();

export const getWheel = createAsyncAction(
  WheelsActionTypes.GET_WHEEL,
  WheelsActionTypes.GET_WHEEL_SUCCESS,
  WheelsActionTypes.GET_WHEEL_FAILURE,
  WheelsActionTypes.GET_WHEEL_CLEAR,
)<number, Wheel, ResponseError, void>();

export const getTireSizes = createAsyncAction(
  WheelsActionTypes.GET_TIRE_SIZES,
  WheelsActionTypes.GET_TIRE_SIZES_SUCCESS,
  WheelsActionTypes.GET_TIRE_SIZES_FAILURE,
  WheelsActionTypes.GET_TIRE_SIZES_CLEAR,
)<BaseFilter, TireSize[], ResponseError, void>();

export const getVehicles = createAsyncAction(
  WheelsActionTypes.GET_VEHICLES,
  WheelsActionTypes.GET_VEHICLES_SUCCESS,
  WheelsActionTypes.GET_VEHICLES_FAILURE,
  WheelsActionTypes.GET_VEHICLES_CLEAR,
)<undefined, Vehicle[], ResponseError, void>();

export const createTireSize = createAsyncAction(
  WheelsActionTypes.CREATE_TIRE_SIZE,
  WheelsActionTypes.CREATE_TIRE_SIZE_SUCCESS,
  WheelsActionTypes.CREATE_TIRE_SIZE_FAILURE,
  WheelsActionTypes.CREATE_TIRE_SIZE_CLEAR,
)<CreateTireSize & { callback?: (tireSize: TireSize) => void }, TireSize, ResponseError, void>();

export const createVehicle = createAsyncAction(
  WheelsActionTypes.CREATE_VEHICLE,
  WheelsActionTypes.CREATE_VEHICLE_SUCCESS,
  WheelsActionTypes.CREATE_VEHICLE_FAILURE,
  WheelsActionTypes.CREATE_VEHICLE_CLEAR,
)<CreateVehicle & { callback?: (vehicle: Vehicle) => void }, Vehicle, ResponseError, void>();

export const createVehicleModel = createAsyncAction(
  WheelsActionTypes.CREATE_VEHICLE_MODEL,
  WheelsActionTypes.CREATE_VEHICLE_MODEL_SUCCESS,
  WheelsActionTypes.CREATE_VEHICLE_MODEL_FAILURE,
  WheelsActionTypes.CREATE_VEHICLE_MODEL_CLEAR,
)<
  CreateVehicleModel & { callback?: (vehicleModel: VehicleModel, vehicle_id: number) => void },
  VehicleModel & { vehicle_id: number },
  ResponseError,
  void
>();

export const updateWheel = createAsyncAction(
  WheelsActionTypes.UPDATE_WHEEL,
  WheelsActionTypes.UPDATE_WHEEL_SUCCESS,
  WheelsActionTypes.UPDATE_WHEEL_FAILURE,
)<UpdateWheel, UpdateWheelSuccess, ResponseError>();

export const addWheelPictures = createAsyncAction(
  WheelsActionTypes.ADD_WHEEL_PICTURES,
  WheelsActionTypes.ADD_WHEEL_PICTURES_SUCCESS,
  WheelsActionTypes.ADD_WHEEL_PICTURES_FAILURE,
)<AddWheelPicturesDto & { id: number }, UpdateWheelSuccess, ResponseError>();

export const deleteWheel = createAsyncAction(
  WheelsActionTypes.DELETE_WHEEL,
  WheelsActionTypes.DELETE_WHEEL_SUCCESS,
  WheelsActionTypes.DELETE_WHEEL_FAILURE,
)<number, void, ResponseError>();

export const deleteWheelPicture = createAsyncAction(
  WheelsActionTypes.DELETE_WHEEL_PICTURE,
  WheelsActionTypes.DELETE_WHEEL_PICTURE_SUCCESS,
  WheelsActionTypes.DELETE_WHEEL_PICTURE_FAILURE,
)<{ wheel_id: number; picture_id: number }, void, ResponseError>();

export const setFilter = createAction(WheelsActionTypes.SET_FILTER)<WheelsFilter | null>();
export const clearWheelsList = createAction(WheelsActionTypes.CLEAR_WHEELS_LIST)();
