import React, { useMemo } from "react";
import { Option } from "@shared/interfaces";
import { Box, Flex } from "@chakra-ui/react";

import { BadgesList } from "./";

export interface GroupBadgesListProps<T> {
  options: Option<string, T & { name?: string }>[];
  onRemove: (id: number) => void;
}

export const GroupBadgesList = <T,>(props: GroupBadgesListProps<T>) => {
  const { options, onRemove } = props;

  const groupedOptions = useMemo(() => {
    return options.reduce((r, i) => {
      if (!i?.object?.name) return r;

      r[i.object.name] = r[i.object.name] || [];
      r[i.object.name].push(i);

      return r;
    }, {} as Record<string, Option[]>);
  }, [options]);

  if (!options.length) {
    return null;
  }

  return (
    <Flex flexDir="column">
      {Object.keys(groupedOptions).map((key) => (
        <Box key={key} mt="15px">
          <BadgesList key={key} title={key} options={groupedOptions[key]} onRemove={onRemove} />
        </Box>
      ))}
    </Flex>
  );
};

export default GroupBadgesList;
