import {
  AddWheelPicturesDto,
  UpdateWheelData,
  WheelUploadPicturesShape,
  WheelPictureDto,
} from "@containers/Wheels/interface";
import * as Yup from "yup";
import { FormikProps } from "formik";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { WHEEL_PICTURE_TYPE } from "@containers/Wheels/constants";

export type SetFieldValueType = FormikProps<UpdateWheelData>["setFieldValue"];

export const initValues: WheelUploadPicturesShape = { picture_types: [], vehicles: [], tire_sizes: [], files: [] };

export const validationSchema = Yup.object({
  picture_types: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1, FORM_ERROR_MESSAGES.REQUIRED),
  vehicles: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1, FORM_ERROR_MESSAGES.REQUIRED),
  tire_sizes: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1, FORM_ERROR_MESSAGES.REQUIRED),
  files: Yup.array().of(Yup.string()).min(1, FORM_ERROR_MESSAGES.REQUIRED),
});

export const prepareFormValues = (values: WheelUploadPicturesShape): AddWheelPicturesDto => {
  let wheelPictures: WheelPictureDto[] = values.vehicles.map((v) => ({
    picture_type: values.picture_types[0].value as WHEEL_PICTURE_TYPE,
    vehicle_id: Number(v.object?.id),
    vehicle_model_id: Number(v.value),
  }));

  if (values.tire_sizes.length) {
    wheelPictures = values.tire_sizes.reduce((r, i) => {
      if (!wheelPictures.length) {
        return [
          ...r,
          { picture_type: values.picture_types[0].value as WHEEL_PICTURE_TYPE, tire_size_id: Number(i.value) },
        ];
      }

      const wheelPicturesWithTireSize: WheelPictureDto[] = wheelPictures.map((wp) => ({
        ...wp,
        tire_size_id: Number(i.value),
      }));

      return [...r, ...wheelPicturesWithTireSize];
    }, [] as WheelPictureDto[]);
  }

  return {
    base64_pictures: values.files,
    wheel_pictures: wheelPictures,
  };
};
