import React, { useCallback, useMemo } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Select, MultiValue, SingleValue, GroupBase } from "chakra-react-select";
import { Option as SelectOption } from "@shared/interfaces";
import { useDispatch } from "react-redux";
import {
  chakraStyles,
  getCustomCheckboxesComponents,
  getCustomComponents,
} from "@shared/components/Field/MultipleSelect/multipleSelectHelpers";

export interface MultipleSelectProps {
  name?: string;
  isMulti: boolean;
  label: string;
  options: SelectOption<string>[] | GroupBase<SelectOption<string>>[];
  selected: SelectOption<string>[];
  setSelected: (selected: SelectOption<string>[]) => void;
  isSearchable?: boolean;
  width?: string;
  isRequired?: boolean;
  onAddItem?: (name: string) => void;
  addItemModalType?: string;
}

const MultipleSelect: React.FC<MultipleSelectProps> = (props) => {
  const {
    isMulti,
    label,
    options,
    selected,
    setSelected,
    isSearchable,
    width,
    isRequired,
    onAddItem,
    addItemModalType,
    name,
  } = props;
  const dispatch = useDispatch();
  const onChange = useCallback(
    (options: SingleValue<SelectOption<string>> | MultiValue<SelectOption<string>>) => {
      setSelected(Array.isArray(options) ? [...options] : [options]);
    },
    [setSelected],
  );

  const customCheckboxesComponents = useMemo(() => {
    return getCustomCheckboxesComponents(onAddItem, addItemModalType, dispatch);
  }, [addItemModalType, dispatch, onAddItem]);

  const customComponents = useMemo(() => {
    return getCustomComponents(onAddItem, addItemModalType, dispatch);
  }, [addItemModalType, dispatch, onAddItem]);

  return (
    <Box w={width || "full"} flexShrink={0}>
      <Text color="brand.secondarySubtitle" ml="16px" mb="3px" fontSize="14px">
        {label}&nbsp;
        {isRequired && (
          <Text as="span" color="brand.secondary">
            *
          </Text>
        )}
      </Text>
      <Select
        name={name}
        isMulti={isMulti}
        placeholder="Select"
        isSearchable={isSearchable}
        options={options}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        isClearable={false}
        controlShouldRenderValue={!isMulti}
        value={selected}
        onChange={onChange}
        components={isMulti ? customCheckboxesComponents : customComponents}
        chakraStyles={chakraStyles}
      />
    </Box>
  );
};

export default MultipleSelect;
