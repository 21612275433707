import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Option } from "@shared/interfaces";

import BadgeItem from "./BadgeItem";

export interface BadgesListProps {
  title?: string;
  options: Option[];
  onRemove: (id: number) => void;
}

export const BadgesList = (props: BadgesListProps) => {
  const { options, onRemove, title } = props;

  if (!options.length) {
    return null;
  }

  return (
    <Flex gap="8px" flexWrap="wrap" alignItems="center">
      {title ? <Text>{title}:</Text> : null}
      <Flex gap="8px" flexWrap="wrap">
        {options.map((badge) => (
          <BadgeItem key={badge.value} id={Number(badge.value)} name={String(badge.label)} onRemove={onRemove} />
        ))}
      </Flex>
    </Flex>
  );
};

export default BadgesList;
