import { all, fork } from "redux-saga/effects";

import { saga as sharedSaga } from "../shared/store";
import { saga as authSaga } from "../containers/Auth/store";
import { saga as wheelsSaga } from "../containers/Wheels/store";

const allSagas = [sharedSaga, authSaga, wheelsSaga];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
