import React from "react";
import { Box, Heading } from "@chakra-ui/react";

export interface WheelViewVerticalTableProps {
  properties: { title: string; value: string | number; isBold?: boolean }[];
  blockTitle?: string;
  partNumber?: string;
}

const WheelViewVerticalTable: React.FC<WheelViewVerticalTableProps> = ({ properties, blockTitle, partNumber }) => {
  return (
    <>
      <Heading as="h4" fontSize="12px" color="brand.secondarySubtitle" letterSpacing="1.2px" fontWeight="600" mb="8px">
        {blockTitle}
      </Heading>
      <Box display="flex" alignItems="center" marginBottom="12px">
        <Box fontSize="14px" color="brand.secondarySubtitle" fontWeight="400" marginRight="8px">
          Part Number
        </Box>
        <Box fontWeight="700">{partNumber}</Box>
      </Box>
      <Box display="flex" flexDirection="row" width="100%" flexWrap="wrap">
        {properties.map(({ title, value }, i) => (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            key={i}
            mb="24px"
            minWidth="25%"
          >
            <Box fontSize="14px" color="brand.secondarySubtitle" fontWeight="400">
              {title}
            </Box>
            <Box fontWeight="400">{value || "N/A"}</Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default WheelViewVerticalTable;
