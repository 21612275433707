import React from "react";
import { MenuItem } from "@shared/interfaces";
import { Icon } from "@chakra-ui/react";
import { ReactComponent as NavbarIcon } from "@assets/files/icons/navbar/wheels-list.svg";

import { NamesOfParentRoutes } from "./routes";

export const SIDEBAR_ITEMS: MenuItem[] = [
  {
    name: "Wheels",
    path: `${NamesOfParentRoutes.WHEELS}`,
    icon: (
      <Icon boxSize="28px" color="brand.secondary" mt="8px">
        <NavbarIcon />
      </Icon>
    ),
  },
];
