import { createAsyncAction } from "typesafe-actions";

import { AuthActionTypes } from "./constants";
import { LoginShape } from "../interface";

export const login = createAsyncAction(
  AuthActionTypes.LOGIN,
  AuthActionTypes.LOGIN_SUCCESS,
  AuthActionTypes.LOGIN_FAILURE,
)<LoginShape, undefined, Error>();

export const logout = createAsyncAction(
  AuthActionTypes.LOGOUT,
  AuthActionTypes.LOGOUT_SUCCESS,
  AuthActionTypes.LOGOUT_FAILURE,
)<undefined, undefined, Error>();
